<template>
  <div v-show="visible" class="notice" :class="classes" @click="click()">
    <slot>{{ message }}</slot>
  </div>
</template>

<script setup>
import {
  computed,
  defineEmits, defineProps, onMounted, ref,
} from 'vue';

const props = defineProps({
  timeout: Number,
  closable: Boolean,
  message: String,
  severity: String,
});

const emit = defineEmits(['close']);

const classes = computed(() => [
  props.severity ? `notice_${props.severity}` : '',
  {
    notice_closable: props.closable,
  },
]);

const visible = ref(false);

const close = () => {
  visible.value = false;
  emit('close');
};

let closeTimerId = null;
const show = () => {
  clearTimeout(closeTimerId);
  visible.value = true;
  if (props.timeout) {
    closeTimerId = setTimeout(close, props.timeout);
  }
};

const click = () => {
  if (!props.closable) {
    return;
  }
  close();
};

onMounted(() => {
  show();
});
</script>

<style lang="scss">
.notice{
  line-height: 1.5;
  padding: 1em;
  background: #ffffe0;
  border-radius: 5px;
  box-sizing: border-box;

  > p {
    margin: 1em 0 0 0;
    &:first-child{
      margin-top: 0;
    }
  }
}
.notice_closable{
  cursor: pointer;
}
.notice_error{
  background: #ffa6a6;
}
.notice_info{
  background: #ddf2ff;
}
</style>
