<template>
  <div class="chat-form-attachments">
    <Attachment
      v-for="item in items"
      :key="item"
      :busy="item.busy"
      :error="item.error"
      :icon="getAttachmentIcon(item.type)"
      @close="attachments.remove(item)">
      <img v-if="item.type === 'image'"
        :src="item.preview ?? item.content?.url" alt=""/>
      <div v-else-if="item.type === 'video_text'" class="attachment-name">{{ item.content?.title }}</div>
      <div v-else-if="item.type === 'webpage'" class="attachment-name">{{ item.content?.url }}</div>
<!--      <div v-else-if="/webpage|video_text/.test(item.type)" class="attachment-name">{{ item.content?.url }}</div>-->
      <div v-else class="attachment-name">{{ item.name ?? item.content?.url.split('/').pop()  }}</div>
      <div v-if="item.error" class="error-text">{{ item.error }}</div>
    </Attachment>
    <InputUrlModal ref="inputUrlModal" @confirm="attachments.addWebpage($event)" />
    <InputYouTubeModal ref="inputYouTubeModal" @confirm="attachments.addYoutube($event)" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import selectFiles from '@/utils/select-files';
import Attachment from '@/components/Attachment.vue';
import InputUrlModal from '@/components/modals/InputUrl.vue';
import InputYouTubeModal from '@/components/InputYouTube.vue';

const props = defineProps({
  attachments: { type: Object, require: true },
});

const items = computed(() => props.attachments.items.value);

const attachmenIcons = {
  webpage: 'globe',
  video_text: 'youtube',
  image: 'image',
};

const getAttachmentIcon = (type) => attachmenIcons[type] ?? 'clip';

const attachImage = () => selectFiles({ multiple: true, accept: 'image/jpg,image/jpeg,image/webp,image/png' })
  .then((files) => Array.from(files).forEach((file) => props.attachments.addFile(file)))
  .catch(() => false);

const attachFile = () => selectFiles({ multiple: true })
  .then((files) => Array.from(files).forEach((file) => props.attachments.addFile(file)))
  .catch(() => false);

const inputUrlModal = ref(null);
const attachWebpage = () => {
  inputUrlModal.value?.show();
};

const inputYouTubeModal = ref(null);
const attachYoutube = () => {
  inputYouTubeModal.value?.show();
};

defineExpose({
  attachFile,
  attachImage,
  attachWebpage,
  attachYoutube,
});
</script>

<style lang="scss">
.chat-form-attachments{
  display: flex;
  gap: .5em;
  flex-wrap: wrap;

  .attachment{
    max-width: 300px;
  }
  .attachment img {
    max-width: 32px;
    max-height: 16px;
    border-radius: 3px;
    display: block;
  }
  .attachment .attachment-name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .attachment .error-text{
    font-size: 10px;
    margin-top: .25em;
  }
}
</style>
