<template>
  <!--  <svg height="1em" width="1em" viewBox="0 0 78 78" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" fill="currentColor">-->
  <!--    <path d="M39,0C17.461,0,0,17.461,0,39s17.461,39,39,39s39-17.461,39-39S60.539,0,39,0z M40.221,70.934  c-0.445,0.399-0.907,0.483-1.217,0.483c-0.313,0-0.78-0.085-1.232-0.491c-5.489-4.938-9.529-11.251-11.828-18.24  c8.567-2.189,17.566-2.219,26.146-0.091C49.795,59.624,45.738,65.974,40.221,70.934z M24.474,46.87  c-0.469-2.574-0.724-5.205-0.724-7.87s0.255-5.296,0.723-7.87c4.827,1.231,9.776,1.849,14.727,1.849  c4.82,0,9.639-0.587,14.345-1.754c0.457,2.544,0.706,5.143,0.706,7.775s-0.249,5.231-0.706,7.775  C44.005,44.409,34,44.439,24.474,46.87z M37.78,7.066c0.444-0.399,0.907-0.483,1.216-0.483c0.313,0,0.78,0.086,1.232,0.491  c5.513,4.959,9.566,11.306,11.86,18.331c-8.58,2.127-17.579,2.098-26.146-0.091C28.242,18.322,32.286,12.005,37.78,7.066z   M59.318,29.472c3.287-1.181,6.489-2.663,9.569-4.441C70.88,29.276,72,34.009,72,39s-1.12,9.724-3.112,13.969  c-3.08-1.779-6.282-3.26-9.569-4.441c0.604-3.106,0.932-6.293,0.932-9.528C60.25,35.765,59.923,32.579,59.318,29.472z   M65.854,19.855c-2.59,1.494-5.275,2.759-8.029,3.782c-1.917-5.907-4.934-11.373-8.866-16.099  C55.838,9.721,61.742,14.104,65.854,19.855z M29.042,7.539c-3.901,4.689-6.899,10.107-8.817,15.961  c-2.732-1.037-5.396-2.305-7.963-3.807C16.368,14.022,22.227,9.701,29.042,7.539z M9.193,24.85c3.061,1.793,6.244,3.292,9.513,4.491  C18.085,32.489,17.75,35.72,17.75,39c0,3.28,0.334,6.511,0.956,9.659c-3.269,1.2-6.452,2.698-9.513,4.491  C7.148,48.859,6,44.062,6,39S7.148,29.141,9.193,24.85z M12.262,58.306c2.566-1.501,5.23-2.77,7.963-3.807  c1.918,5.854,4.916,11.273,8.817,15.962C22.227,68.299,16.368,63.978,12.262,58.306z M48.959,70.461  c3.932-4.727,6.948-10.192,8.865-16.099c2.754,1.023,5.439,2.289,8.029,3.782C61.742,63.895,55.838,68.278,48.959,70.461z" />-->
  <!--  </svg>-->
  <svg height="1em" width="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    <path
      d="M20.9285035,10.8601361 C20.5127682,7.57076782 18.3216365,4.83471059 15.3454503,3.64230648 C16.3944383,5.6673038 17,8.67235871 17,12 C17,12.1520704 16.9987353,12.3034672 16.9962215,12.4541275 C18.9736842,12.015741 20.4579577,11.3883247 20.9285035,10.8601361 Z M20.9211723,13.1964288 C19.8610141,13.7478635 18.4647076,14.2089641 16.8792306,14.5268844 C16.6538514,16.8289028 16.1207697,18.8609941 15.3454503,20.3576935 C18.3045601,19.172131 20.4875985,16.4605785 20.9211723,13.1964288 Z M3.10296429,13.3657859 C3.58836952,16.5541891 5.74676893,19.1926959 8.65454975,20.3576935 C7.88866859,18.879214 7.35916332,16.8782983 7.12913699,14.610834 C5.52333518,14.3317144 4.14644262,13.9092752 3.10296429,13.3657859 Z M3.06564935,10.9074041 C3.35718942,11.2805411 4.16781668,11.7579761 5.40825505,12.1475117 C5.89667659,12.300891 6.43159753,12.4357216 7.00555318,12.5502212 C7.00186026,12.3678797 7,12.1844353 7,12 C7,8.67235871 7.60556171,5.6673038 8.65454975,3.64230648 C5.66411911,4.84041759 3.46627566,7.59699344 3.06564935,10.9074041 Z M14.9862915,12.8045103 C14.9953365,12.5396718 15,12.2713459 15,12 C15,6.98399319 13.4063972,3 12,3 C10.5936028,3 9,6.98399319 9,12 C9,12.2869698 9.00521598,12.5705617 9.01531448,12.8502229 C9.95340738,12.9482908 10.9557722,13 12,13 C13.0280488,13 14.0367397,12.9274936 14.9862915,12.8045103 Z M14.8252793,14.8441424 C13.9124489,14.9453996 12.9633249,15 12,15 C11.0208804,15 10.0760086,14.9581505 9.17890858,14.8773334 C9.6334669,18.4574369 10.8764702,21 12,21 C13.1269987,21 14.3742099,18.441712 14.8252793,14.8441424 Z M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z"
      fill-rule="evenodd"/>
  </svg>
</template>
<script setup>
</script>
