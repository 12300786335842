const { clipboard } = navigator;

const supports = !!clipboard;

const writeText = (text) => {
  if (!supports) {
    return;
  }
  clipboard.writeText(text);
};

export default {
  supports,
  writeText,
};
