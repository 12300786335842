import { createApp } from 'vue';
import { createPinia } from 'pinia';
import VueNumerals from 'vue-numerals';
import moment from 'moment';
import 'moment/locale/ru';
import '@/assets/styles.scss';
import useAuthStore from '@/store/auth';
import useChatStore from '@/store/chat';
import useImagingStore from '@/store/imaging';
import App from '@/App.vue';
import router from '@/router/index';
import { createSidebarPortal } from '@/utils/sidebar-portal';
import './registerServiceWorker';

const app = createApp(App);
app.use(createPinia());
app.use(VueNumerals, { locale: 'ru' });
moment.locale('ru');

(async () => {
  await useAuthStore().refreshToken();
  await useChatStore().loadModels();
  await useImagingStore().loadPresets();
  app.use(router);
  app.use(createSidebarPortal());
  app.mount('#app');
})();
