<template>
  <Modal
    ref="modalElement"
    class="imaging-inpaint-modal"
    :contain-to-window="true"
    :no-close="true">
    <Button
      class="imaging-inpaint-modal__close"
      icon="cancel"
      @click="$refs.modalElement.hide()" />
    <ImagingInpaint :image="image" @send="$emit('send', image, $event); $refs.modalElement.hide()" />
  </Modal>
</template>

<script setup>
import { defineExpose, ref } from 'vue';

import Modal from '@/components/Modal.vue';
import ImagingInpaint from '@/components/ImagingInpaint.vue';
import Button from '@/components/Button.vue';

const modalElement = ref(null);
const image = ref(null);

defineEmits(['send']);

defineExpose({
  show: (context) => {
    image.value = context;
    modalElement.value?.show();
  },
});
</script>

<style lang="scss">
.imaging-inpaint-modal{
  .modal__window{
    background: #282828;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    max-height: 675px;
  }
  .modal__content{
    flex-grow: 1;
    display: flex;
  }
  &__close{
    position: absolute;
    right: 1.75em;
    top: 1.75em;
    justify-content: center;
    width: 2.5em;
    height: 2.5em;
    padding: 0;
    border-radius: 50%;
    background: #444;
    color: #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, .15);
    &:hover{
      background: #555;
    }
  }
  .imaging-inpaint{
    width: 100%;
  }
}
</style>
