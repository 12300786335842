<template>
  <teleport to="body" v-if="popupShown">
    <Menu ref="menuElement" class="popup-menu" v-bind="$attrs" :style="popupStyles" @click="hidePopup">
      <slot :context="popupContext"></slot>
    </Menu>
  </teleport>
</template>

<script setup>
import { computed, defineExpose, shallowRef } from 'vue';
import { usePopup, usePopupDefaultProps } from '@/composables/popup';
import Menu from '@/components/Menu.vue';

const props = defineProps({
  ...usePopupDefaultProps(),
});

const menuElement = shallowRef(null);
const popupElement = computed(() => menuElement.value?.$el);
const {
  popupShown,
  popupStyles,
  popupContext,
  showPopup,
  hidePopup,
  togglePopup,
} = usePopup(popupElement, props);

defineExpose({
  show: showPopup,
  hide: hidePopup,
  toggle: togglePopup,
});
</script>

<style lang="scss">
.popup-menu {
  position: fixed;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, .15);
  box-sizing: border-box;
  font-size: 14px;
}
</style>
