<template>
  <div class="plan-list">
    <slot name="default">
      <div class="plan-card" :class="{'plan-card_best': p.isBest}" v-for="p in plans" :key="p.id">
        <div v-if="p.discount" class="plan-card__profit">Экономия {{p.discount}}%</div>
        <div class="plan-card__title">{{ p.title }}</div>
        <Price class="plan-card__price" :price="p.price" />
        <div>в месяц*</div>
        <Separator />
        <div><Price :price="p.unitsLimit" sign="go"/>**</div>
        <div v-if="p.requestsLimit">{{p.requestsLimit}}&thinsp;запросов в день</div>
        <div v-else>Безлимитные запросы</div>
<!--        <div v-if="p.generateDelay > 0">Ожидание ответов</div>-->
<!--        <div v-else>Быстрые ответы</div>-->
        <div>Доступ к GPT-4 Omni Mini</div>
        <div v-if="p.supportsAdvancedChatModels">Доступ к GPT-4 Omni</div>
        <div>Доступ к Claude Haiku</div>
        <div v-if="p.supportsAdvancedChatModels">Доступ к Claude Sonnet и Opus</div>
        <div v-if="p.supportsVision">Распознавание изображений</div>
        <div v-if="p.supportsVision">Обработка ссылок</div>
        <div>Доступ к Midjourney 5.2</div>
        <div v-if="p.supportsAdvancedImagingModels">Доступ к Midjourney 6 и Dalle-3</div>
        <Button @click="$emit('select', p)" v-if="p.canBuy" style="margin-top: auto">Получить</Button>
        <template v-else-if="p.isDefault">
          <Notice severity="error">
            Необходима активная подписка на Telegram-канал @GoGptRu
          </Notice>
          <Button icon="telegram"
                  style="margin-top: auto"
                  @click="telegramGo('GoGptRu')">Подписаться</Button>
        </template>
      </div>
    </slot>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import useTelegramGo from '@/composables/telegram-go';
import Separator from '@/components/Separator.vue';
import Button from '@/components/Button.vue';
import Price from '@/components/Price.vue';
import Notice from '@/components/Notice.vue';

defineProps({
  plans: Array,
});

defineEmits(['select']);

const telegramGo = useTelegramGo();
</script>

<style lang="scss">
.plan-list{
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center;
  .plan-card{
    flex-basis: 200px;
  }
}
</style>
