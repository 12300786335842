<template>
  <div class="step-list">
    <component class="step-list__item" v-for="(item) in items" :key="item" :is="item" />
  </div>
</template>

<script setup>
import { computed, useSlots } from 'vue';

const slots = useSlots();
const items = computed(() => slots.default().reduce((arr, c) => {
  if (c.type.name === 'StepListItem') {
    arr.push(c);
  } else if (c.children && c.children instanceof Array) {
    c.children.forEach((nc) => {
      if (nc.type.name === 'StepListItem') {
        arr.push(nc);
      }
    });
  }
  return arr;
}, []));
</script>

<style lang="scss">
.step-list{
  list-style: none;
  counter-reset: item;
  padding: 0;

  &__item{
    display: flex;
    gap: 1em;
    margin-top: 1.5em;
    padding-top: .5em;
    &:first-child{
      margin-top: 0;
    }
    &:before{
      counter-increment: item;
      content: counters(item,'.');
      font-size: 1.2em;
      width: 1.5em;
      height: 1.5em;
      line-height: 1.5em;
      text-align: center;
      border: solid 1px;
      border-radius: 50%;
      flex-shrink: 0;
      margin-top: -.2em;
    }
  }
}
</style>
