<template>
  <teleport to="body">
    <div ref="modalElement" v-if="isShown" class="modal"
         :class="{modal_blur: isFocus && !noBlur, modal_scroll: isOverflow, modal_busy: busy}"
         v-bind="$attrs" @click="!busy && !noClose && hide()">
      <div ref="windowElement"
           class="modal__window" :class="{'modal__window_contain': containToWindow}"
           :style="{width: width}" @click.stop>
        <div class="modal__close" @click="!busy && !noClose && hide()" v-if="!noClose"><CancelIcon /></div>
        <iframe title="" ref="iframeSizeElement" class="modal__iframe-size" tabindex="-1"></iframe>
        <div v-if="!!$slots.header" class="modal__header">
          <slot name="header" :hide="hide"></slot>
        </div>
        <div class="modal__content">
          <slot :hide="hide"></slot>
        </div>
        <div v-if="!!$slots.footer" class="modal__footer">
          <slot name="footer" :hide="hide"></slot>
        </div>
        <div class="modal__overlay" v-if="busy">
          <Loader class="modal__loader" />
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
const modals = [];

const blurModals = (exceptModal) => {
  for (let i = 0; i < modals.length; i += 1) {
    if (modals[i] !== exceptModal) {
      modals[i].exposed.blur();
    }
  }
};

const pushModal = (modal) => {
  // Переместить модал в конец
  let found = false;
  for (let i = 0; i < modals.length; i += 1) {
    found = modals[i] === modal;
    if (found) {
      break;
    }
  }
  if (!found) {
    modals.push(modal);
  }
  modal.exposed.focus();
  document.body.classList.add('modal-body-no-scroll');
};

const popModal = (modal) => {
  for (let i = 0; i < modals.length; i += 1) {
    if (modals[i] === modal) {
      modals.splice(i, 1);
      break;
    }
  }

  if (modals.length) {
    modals[modals.length - 1].exposed.focus();
    return;
  }
  document.body.classList.remove('modal-body-no-scroll');
};
</script>

<script setup>
// eslint-disable-next-line import/first
import {
  defineProps, defineEmits, defineExpose, ref, getCurrentInstance,
  onMounted, onUnmounted, nextTick, watch,
} from 'vue';
// eslint-disable-next-line import/first
import Loader from '@/components/Loader.vue';
// eslint-disable-next-line import/first
import CancelIcon from '@/components/icons/CancelIcon.vue';

const modalElement = ref(null);
const windowElement = ref(null);
const iframeSizeElement = ref(null);

const props = defineProps({
  width: String,
  noBlur: Boolean,
  noClose: Boolean,
  shown: Boolean,
  busy: Boolean,
  containToWindow: Boolean,
});

const emit = defineEmits([
  'update:shown',
  'show',
  'hide',
  'focus',
  'blur',
]);

const isFocus = ref(false);
const isShown = ref(false);
const instance = getCurrentInstance();
const isOverflow = ref(false);

const show = () => {
  isShown.value = true;
  emit('update:shown', true);
  emit('show');
  window.addEventListener('resize', checkSizes, true);
  nextTick(() => {
    pushModal(instance);
    // eslint-disable-next-line no-unused-expressions
    iframeSizeElement.value?.contentWindow.addEventListener('resize', checkSizes);
    checkSizes();
  });
};

const hide = () => {
  isShown.value = false;
  emit('update:shown', false);
  emit('hide');
  popModal(instance);
  window.removeEventListener('resize', checkSizes, true);
  // eslint-disable-next-line no-unused-expressions
  iframeSizeElement.value?.contentWindow.removeEventListener('resize', checkSizes);
};

const focus = () => {
  const el = modalElement.value;
  if (el == null) {
    return;
  }

  document.body.removeChild(el);
  document.body.appendChild(el);
  blurModals(instance);
  isFocus.value = true;
  emit('focus');
};

const blur = () => {
  isFocus.value = false;
  emit('blur');
};

const checkSizes = () => {
  const modalHeight = modalElement.value?.clientHeight;
  const windowHeight = windowElement.value?.offsetHeight;
  if (modalHeight == null || windowHeight == null) {
    return;
  }
  isOverflow.value = modalHeight <= windowHeight + 100;
};

onMounted(() => {
  watch(() => props.shown, () => {
    if (props.shown) {
      show();
    } else {
      hide();
    }
  });
  if (props.shown) {
    show();
  }
});

onUnmounted(() => {
  hide();
});

defineExpose({
  show,
  hide,
  focus,
  blur,
});
</script>

<style lang="scss">
.modal-body-no-scroll {
  overflow: hidden !important;
}

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  z-index: 1;
  width: 100%;
  height: 100%;

  &_blur {
    background: rgba(0, 0, 0, .8);
  }

  &_scroll {
    align-items: flex-start;
    overflow: hidden;
    overflow-y: auto;
  }

  &__window {
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    max-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    &_contain{
      max-height: 100%;
    }
  }

  &__close {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 5px;
    right: 5px;
    cursor: pointer;
    opacity: .7;
    transition: opacity .3s ease;
    font-size: 16px;
    &:hover{
      opacity: 1;
    }
  }

  &__content {
    overflow-y: auto;
    flex-grow: 0;
    flex-shrink: 1;
  }

  &__header {
    margin-bottom: 15px;
    font-size: 22px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &__footer {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loader {
    width: 70px;
    height: 70px;
    color: #fff;
  }

  &__iframe-size{
    position: absolute;
    right: 100%;
    bottom: 100%;
    width: 5%;
    height: 5%;
    pointer-events: none;
    border: none;
    opacity: 0;
  }
}
</style>
