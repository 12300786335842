<template>
    <a class="gogpt-logo" href="https://gogpt.ru" target="_blank">
      <span>Go</span>GPT
    </a>
</template>

<style lang="scss">
.gogpt-logo{
  display: inline-block;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  span{
    color: #dc3f2d;
  }
}
</style>
