<template>
  <Modal ref="modalElement" class="modal-warning-large-request" @hide="cancel">
    <template #header>Большой запрос</template>
    <div>
      Ваш запрос имеет высокую стоимость <Price :price="cost" sign="go" />!
      <p>Хотите продолжить отправку запроса?</p>
    </div>
    <div class="mt-sm iflex ai-center gap-sm">
      <Toggle v-model:checked="ignoreLargeRequest" />
      <span>больше не показывать для этого чата</span>
    </div>
    <template #footer="{ hide }">
      <div class="button-list">
        <Button type="button" icon="confirm" label="Продолжить" @click="confirm(); hide();" />
        <Button type="button" icon="cancel" label="Отменить" severity="" @click="hide()" />
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { defineExpose, defineEmits, ref } from 'vue';
import useChatStore from '@/store/chat';
import Modal from '@/components/Modal.vue';
import Button from '@/components/Button.vue';
import Toggle from '@/components/Toggle.vue';
import Price from '@/components/Price.vue';

const emit = defineEmits(['confirm', 'cancel']);

const chatStore = useChatStore();
const modalElement = ref(null);
let contextId;
const cost = ref(null);
const ignoreLargeRequest = ref(false);

let resolveShow = null;
let rejectShow = null;
const show = ({ context, cost: requestCost }) => {
  contextId = context?.id;
  cost.value = requestCost;
  modalElement.value?.show();
  return new Promise((resolve, reject) => {
    resolveShow = resolve;
    rejectShow = reject;
  });
};

const confirm = () => {
  if (contextId && ignoreLargeRequest.value) {
    chatStore.saveContext(contextId, { ignoreLargeRequest: true });
  }
  resolveShow?.({ ignoreLargeRequest: ignoreLargeRequest.value });
  emit('confirm');
};

const cancel = () => {
  rejectShow?.();
  emit('cancel');
};

defineExpose({
  show,
});
</script>

<style>
.modal-warning-large-request .modal__window {
  max-width: 600px;
}
</style>
