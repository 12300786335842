<template>
  <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
       stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em"
       xmlns="http://www.w3.org/2000/svg">
    <polyline points="20 6 9 17 4 12"></polyline>
  </svg>
</template>

<script>
export default {
  name: 'ConfirmIcon',
};
</script>
