<template>
  <div ref="rootEl" class="scroll-panel" :class="classes">
    <slot></slot>
  </div>
</template>

<script setup>
import {
  computed, defineProps, nextTick, ref, defineExpose,
} from 'vue';

const props = defineProps({
  v: { type: Boolean, default: true },
  h: { type: Boolean, default: false },
});

const classes = computed(() => ({
  'scroll-panel_v': props.v,
  'scroll-panel_h': props.h,
}));

const rootEl = ref(null);
const scrollToBottom = () => {
  nextTick(() => {
    const el = rootEl.value;
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  });
};

defineExpose({ scrollToBottom });
</script>

<style lang="scss">
.scroll-panel_v {
  overflow-y: auto;
}
.scroll-panel_h {
  overflow-x: auto;
}
</style>
