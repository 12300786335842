<template>
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M84,36a6,6,0,0,0-12,0,24,24,0,0,1-48,0,6,6,0,0,0-12,0A35.9772,35.9772,0,0,0,42,71.3936V84H30a6,6,0,0,0,0,12H66a6,6,0,0,0,0-12H54V71.3936A35.9772,35.9772,0,0,0,84,36Z"/>
      <path
        d="M48,48A12.0157,12.0157,0,0,0,60,36V12a12,12,0,0,0-24,0V36A12.0157,12.0157,0,0,0,48,48Z"/>
    </g>
  </svg>
</template>
