<template>
  <div class="imaging-new-chat">
    <h1 class="text-center">Как использовать мастерскую изображений</h1>
    <StepList class="mt-lg">
      <StepListItem v-for="(step) in steps" :key="step" :title="step.title">
        <div class="shadow-text text-justify" v-html="step.description" />
      </StepListItem>
    </StepList>
  </div>
</template>

<script setup>
import StepList from '@/components/StepList.vue';
import StepListItem from '@/components/StepListItem.vue';

const steps = [
  { title: 'Сформулируйте четкое описание', description: 'Перед созданием изображения, точно определите, что вы хотите на нем видеть. Можно запросить у нейросети в умном чате описание похожей фотографии на английском. Четкое описание повышает вероятность того, что нейросеть сгенерирует желаемое изображение.' },
  { title: 'Настройте параметры', description: 'Выбор правильной модели нейросети и подходящего пресета критичен для качества итогового изображения. Определитесь с размерами и пропорциями, учитывая стоимость генерации. Оптимизация настроек поможет добиться наилучшего результата без перерасхода GoCoin-ов.' },
  { title: 'Доработайте полученный результат', description: 'После отправки запроса и получения результата вы можете использовать итеративное улучшение изображения, добавляя детали в описании. Эта практика позволяет добиться максимальной точности и соответствия изображения вашему видению.' },
  { title: 'Создавайте новые диалоги', description: 'Для каждой отдельной темы изображения создавайте отдельный чат. Это позволит вам поддерживать порядок, избежать смешения результатов и упростит последующий поиск и редактирование изображений.' },
  { title: 'Учитесь работать с нейросетями', description: 'Подпишитесь на наш <a href="https://t.me/GoGptRu" target="_blank" class="link">Telegram-канал GoGptRu</a>. Там мы делимся лайфхаками и полезными фишками. Публикуем шаблоны промтов (запросов) и примеры использования. Учим работать с GPT и нейросетями.' },
];
</script>

<style lang="scss">
</style>
