<template>
  <div>
    <TitleContent :title="title">
      <template #title v-if="$slots.title"><slot name="title"></slot></template>
      <template #content><slot name="content"><slot></slot></slot></template>
    </TitleContent>
  </div>
</template>

<script>
import TitleContent from '@/components/TitleContent.vue';

export default {
  name: 'StepListItem',
  components: { TitleContent },
  props: {
    title: String,
  },
};
</script>
