<template>
  <Modal ref="modalElement" class="modal-input-url"
         @focus="$refs.inputElement.focus()"
         @show="resetForm()">
    <template #header>Страница в интернете</template>
    <form @submit.prevent="confirm" class="form form_st_rows">
      <div class="form__field form__row">
        <input ref="inputElement" type="text" class="form-input" placeholder="https://example.com" v-model.trim="webpage" autocomplete="off" />
      </div>
      <div class="form__field form__row">
        <div class="iflex ai-center gap-sm">
          <Toggle v-model:checked="dropLinks" />
          <span>удалить ссылки на странице</span>
        </div>
      </div>
      <div class="form__field form__row" v-if="error">
        <Notice severity="error" :message="error" />
      </div>
      <div class="form__buttons form__row">
        <Button type="submit" icon="confirm">
          Подтвердить
        </Button>
        <Button type="button" icon="cancel" severity="" @click="$refs.modalElement.hide()">
          Закрыть
        </Button>
      </div>
    </form>
  </Modal>
</template>

<script setup>
import {
  defineEmits, ref, defineExpose,
} from 'vue';
import Modal from '@/components/Modal.vue';
import Button from '@/components/Button.vue';
import Notice from '@/components/Notice.vue';
import Toggle from '@/components/Toggle.vue';

const emit = defineEmits([
  'confirm',
]);
//
const modalElement = ref(null);
const error = ref(null);
const webpage = ref(null);
const dropLinks = ref(true);
//
const resetForm = () => {
  error.value = '';
  webpage.value = '';
  dropLinks.value = true;
};

const confirm = () => {
  if (!/^https?:\/\/.+/.test(webpage.value)) {
    error.value = 'Введите корректный адрес страницы';
    return;
  }
  emit('confirm', { url: webpage.value, dropLinks: dropLinks.value });
  modalElement.value?.hide();
};

defineExpose({
  show: () => modalElement.value?.show(),
});
</script>

<style lang="scss">
.modal-input-url .modal__window{
  width: 100%;
  max-width: 400px;
}
</style>
