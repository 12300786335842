<template>
<div class="menu">
  <slot />
</div>
</template>

<script setup>
</script>

<style lang="scss">
.menu{
  padding: .5em;
  border-radius: 3px;
  background: #202023;
  color: #fff;
  overflow-y: auto;
}
</style>
