<template>
  <div class="dashboard">
    <Toolbar class="dashboard__header">
      <template #start>
        <NavButton @click="menuShown = true" icon="menu"/>
        <router-link to="/" custom v-slot="{ navigate }">
          <NavButton @click="navigate" icon="plus"/>
        </router-link>
      </template>
      <template #center>
        <GoGptLogo/>
      </template>
      <template #end>
        <NavButton v-if="sidebarHasContent"
                   icon="aside-right"
                   @click="sidebarShown = !sidebarShown"/>
      </template>
    </Toolbar>
    <div class="dashboard__quick-buttons">
      <Button v-if="sidebarHasContent"
              icon="aside-right"
              severity=""
              class="dashboard__sidebar-button"
              @click="sidebarShown = !sidebarShown"/>
    </div>
    <div ref="navContainerElement" class="dashboard__nav"
         :class="{'dashboard__nav_shown': menuShown}">
      <Nav @close="menuShown=false" :close-button="menuShown"/>
    </div>
    <slot class="dashboard__content"></slot>
    <aside class="dashboard__sidebar" :class="{'dashboard__sidebar_shown': sidebarHasContent && sidebarShown}">
      <Button icon="cancel"
              severity=""
              class="dashboard__sidebar-close"
              @click="sidebarShown = false"/>
      <SidebarPortal @changed="sidebarHasContent = $event.hasContent"/>
    </aside>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useAccountSore from '@/store/account';
import useChatStore from '@/store/chat';
import useImagingStore from '@/store/imaging';
import Nav from '@/components/Nav.vue';
import NavButton from '@/components/NavButton.vue';
import GoGptLogo from '@/components/GoGptLogo.vue';
import SidebarPortal from '@/components/SidebarPortal.vue';
import Button from '@/components/Button.vue';
import Toolbar from '@/components/Toolbar.vue';
import { useWindowClick, useWindowResize } from '@/composables/event-listener';

const sidebarHasContent = ref(false);

const router = useRouter();
const menuShown = ref(false);
const sidebarShown = ref(false);
const navContainerElement = ref(null);
const hideMenu = () => {
  menuShown.value = false;
};
const hideSidebar = () => {
  sidebarShown.value = false;
};

router.afterEach(() => {
  hideMenu();
  hideSidebar();
});

Promise.all([
  useChatStore().loadContexts(),
  useImagingStore().loadContexts(),
  useAccountSore().update(),
]).catch(() => null);

const onDocumentClick = (e) => {
  if (!navContainerElement.value?.contains(e.target)) {
    hideMenu();
  }
};

const onWindowResize = () => {
  hideMenu();
  hideSidebar();
};

useWindowResize(onWindowResize);
useWindowClick(onDocumentClick);
</script>

<style lang="scss">
.dashboard {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__header {
    background: #323235;
    color: #fff;
    padding: .5em;
    box-shadow: 0 0 10px rgba(0, 0, 0, .5);
    z-index: 1;
    display: grid;
    grid-template: auto / auto auto auto;

    .toolbar{
      &__start, &__end{
        gap: .5em;
        z-index: 1;
      }
      &__start{
        grid-area: 1 / 1;
      }
      &__end {
        grid-area: 1 / 3;
      }
      &__center{
        grid-area: 1 / 1 / 1 / 4;
        z-index: 0;
      }
    }
    .toolbar__start, .toolbar__end {
      gap: .5em;
    }

    .nav-button {
      padding: .7em;
    }
  }

  &__quick-buttons {
    display: flex;
    gap: .5em;
    position: fixed;
    top: .5em;
    right: .5em;
    z-index: 1;

    .button {
      padding: .5em;
    }
  }

  &__nav {
    font-size: 14px;
    flex-basis: 270px;
    width: 270px;
    flex-shrink: 0;
    display: none;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    box-shadow: 0 0 10px rgba(0, 0, 0, .5);

    &_shown {
      display: block;
    }
  }

  &__content {
    flex-grow: 1;
    height: 0;
    box-sizing: border-box;
  }

  &__sidebar {
    box-sizing: border-box;
    flex-grow: 1;
    display: none;
    background: #fafafa;
    border-left: solid #dadada 1px;
    .sidebar-portal{
      height: 100%;
    }
  }

  &__sidebar_shown {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 480px;
    box-shadow: -3px 0 5px rgba(0,0,0,.15);
    z-index: 1;
    height: 100%;
  }

  &__sidebar-button{
    display: none;
  }

  &__sidebar-close{
    position: absolute;
    padding: .5em;
    right: .5em;
    top: .5em;
  }
}

@media (min-width: 960px) {
  .dashboard {
    flex-direction: row;

    &__header {
      display: none;
    }

    &__nav {
      position: static;
      display: block;
      box-shadow: none;
    }

    &__content {
      height: 100%;
    }

    &__sidebar-button {
       display: inline-flex;
     }
  }
}

@media (min-width: 1600px) {
  .dashboard {
    &__content {
      flex-basis: 960px;
    }

    &__sidebar {
      display: block;
      flex-basis: 370px;
    }

    &__sidebar-button, &__sidebar-close{
      display: none;
    }
  }
}
</style>
