import { ref } from 'vue';
import api from '@/utils/api';

export default function useChatAttachments() {
  const items = ref([]);

  const clear = () => {
    items.value = [];
  };

  const add = (item) => {
    items.value.push(item);
    return items.value.slice(-1)[0];
  };

  const remove = (item) => {
    // eslint-disable-next-line no-bitwise
    items.value.splice(items.value.indexOf(item) >>> 0, 1);
  };

  const create = async (type, content, process) => {
    const item = add({ type, content, busy: true });
    try {
      if (process) {
        await process(item);
      }
      const attachmentId = await api.post('chat/attachment/create', {
        type: item.type,
        content: item.content,
      });
      const attachment = await api.get('chat/attachment/get', { params: { id: attachmentId } });
      Object.assign(item, attachment);
    } catch (error) {
      item.error = error.message;
    }
    item.busy = false;
  };

  const previewImage = (file) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await api.post(
      'file/upload',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
    return response.url;
  };

  const validateFileSize = (file, maxSizeMb) => {
    if (file.size > maxSizeMb * 1024 * 1024) {
      throw new Error(`Файл должен быть не более ${maxSizeMb} Мб.`);
    }
  };

  const validateFile = async (file) => {
    const fileExt = file.name.toLowerCase().split('.').pop();
    if (/^(pdf|docx?|xlsx?)$/.test(fileExt)) {
      return;
    }
    const arrayBuffer = await file.arrayBuffer();
    const int8 = new Uint8Array(arrayBuffer.slice(0, 1024));
    if (int8.includes(0)) {
      throw new Error('Неподдерживаемый тип файла.');
    }
  };

  const addImage = (file) => create('image', {}, async (item) => {
    item.name = file.name;
    item.preview = await previewImage(file);
    validateFileSize(file, 5);
    item.content.url = await uploadFile(file);
  });

  const addFile = async (file) => {
    if (/^image/i.test(file.type)) {
      return addImage(file);
    }
    return create('file', {}, async (item) => {
      item.name = file.name;
      validateFileSize(file, 2);
      await validateFile(file);
      item.content.url = await uploadFile(file);
    });
  };

  const addWebpage = (webpage) => create('webpage', webpage);
  const addYoutube = (webpage) => create('video_text', webpage);

  return {
    items,
    clear,
    add,
    remove,
    addFile,
    addWebpage,
    addYoutube,
  };
}
