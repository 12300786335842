export function getElementOriginPoint(element, origin = 'left top', relative = true) {
  const {
    left, right, top, bottom,
  } = element.getBoundingClientRect();

  let x = left;
  if (origin.includes('right')) {
    x = right;
  } else if (!origin.includes('left')) {
    x += (right - left) / 2;
  }

  let y = top;
  if (origin.includes('bottom')) {
    y = bottom;
  } else if (!origin.includes('top')) {
    y += (bottom - top) / 2;
  }

  if (relative) {
    x -= left;
    y -= top;
  }
  return { x, y };
}

export function getElementPosition(
  element,
  originPoint,
  elementOrigin = 'left top',
  horizontalMargin = 0,
  verticalMargin = 0,
) {
  const { clientWidth, clientHeight } = document.documentElement;
  const { width: popupWidth, height: popupHeight } = element.getBoundingClientRect();
  const offset = getElementOriginPoint(element, elementOrigin, true);
  const location = {};

  if (elementOrigin.includes('right')) {
    location.right = clientWidth - Math.max(originPoint.x - horizontalMargin, popupWidth);
  } else {
    location.left = Math.min(originPoint.x - offset.x + horizontalMargin, clientWidth - popupWidth);
  }
  if (elementOrigin.includes('bottom')) {
    location.bottom = clientHeight - Math.max(originPoint.y - verticalMargin, popupHeight);
  } else {
    location.top = Math.min(originPoint.y - offset.y + verticalMargin, clientHeight - popupHeight);
  }

  return location;
}

export function getElementPositionRelative(
  element,
  relativeElement,
  elementOrigin = 'left top',
  relativeElementOrigin = 'left top',
  horizontalMargin = 0,
  verticalMargin = 0,
) {
  const originPoint = getElementOriginPoint(relativeElement, relativeElementOrigin, false);
  return getElementPosition(element, originPoint, elementOrigin, horizontalMargin, verticalMargin);
}
