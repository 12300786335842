import { reactive, readonly } from 'vue';

export default (delay, action) => {
  const handler = reactive({
    secondsLeft: delay + 1,
    pending: true,
    cancel() {
      handler.pending = false;
    },
    call() {
      if (!handler.pending) {
        return;
      }
      handler.pending = false;
      action();
    },
  });

  const tick = () => {
    if (!handler.pending) {
      return;
    }
    if (handler.secondsLeft > 0) {
      handler.secondsLeft -= 1;
      setTimeout(tick, 1000);
      return;
    }
    handler.call();
  };
  tick();
  return readonly(handler);
};
