<template>
  <div class="imaging-form">
    <Notice
      v-if="errorMessage"
      class="imaging-form__error"
      :closable="true"
      :timeout="5000"
      severity="error"
      :message="errorMessage"
      @close="errorMessage=''" />
    <Prompt
      ref="promptEl"
      class="imaging-form__prompt"
      v-model:prompt="userPrompt"
      placeholder="Описание изображения"
      :busy="sendProcess"
      @send="send" />
    <ImagingFormOptions
      class="imaging-form__options"
      v-model:preset-id="context.presetId"
      v-model:append-mode="context.appendMode"/>
    <UpgradePlan ref="upgradePlanModal" model="Dall·E" />
    <SubscribeTgChannelModal ref="subscribeTgChannelModal" />
  </div>
</template>

<script setup>
import {
  reactive, ref, watch, defineProps, onMounted, nextTick,
} from 'vue';
import {
  onBeforeRouteLeave, onBeforeRouteUpdate, useRoute, useRouter,
} from 'vue-router';
import useAppStore from '@/store/app';
import useImagingStore from '@/store/imaging';
import Prompt from '@/components/Prompt.vue';
import ImagingFormOptions from '@/components/ImagingFormOptions.vue';
import Notice from '@/components/Notice.vue';
import UpgradePlan from '@/components/modals/UpgradePlan.vue';
import SubscribeTgChannelModal from '@/components/modals/SubscribeTgChannel.vue';

const router = useRouter();
const appStore = useAppStore();
const imagingStore = useImagingStore();
const upgradePlanModal = ref(null);
const subscribeTgChannelModal = ref(null);

const promptEl = ref(null);

defineProps({
  context: Object,
});

const route = useRoute();

const context = reactive({
  id: null,
  presetId: null,
  appendMode: false,
});

const userPrompt = ref(localStorage.getItem(`prompt_imaging${route.params.contextId}`) ?? '');

const findDefaultPreset = () => {
  const defaultModel = imagingStore.models.find((m) => m.isDefault && !m.isHidden) ?? imagingStore.models[0];
  return imagingStore.presets.find((p) => p.modelId === defaultModel.id) ?? imagingStore.presets[0] ?? null;
};

const saveDraft = () => {
  const prompt = userPrompt.value.trim();
  const key = `prompt_imaging${route.params.contextId}`;
  if (prompt) {
    localStorage.setItem(key, prompt);
  } else {
    localStorage.removeItem(key);
  }
};

onBeforeRouteUpdate(saveDraft);
onBeforeRouteLeave(saveDraft);

watch(() => imagingStore.context, (ctx) => {
  context.id = ctx?.id;
  context.presetId = ctx?.presetId ?? findDefaultPreset()?.id;
  context.appendMode = ctx?.appendMode ?? false;
}, { immediate: true, deep: true });

const clear = () => {
  userPrompt.value = '';
};

const sendProcess = ref(false);
const errorMessage = ref('');

const saveContext = async () => {
  const fields = { ...context };
  if (!fields.id) {
    fields.title = userPrompt.value.substring(0, 50);
  }
  context.id = await imagingStore.saveContext(fields.id, fields);
  return context.id;
};

const send = async () => {
  sendProcess.value = true;

  const newContext = !context.id;
  try {
    await saveContext();

    const fileds = {
      contextId: context.id,
      userPrompt: userPrompt.value,
    };
    await imagingStore.createImage(fileds);
  } catch (ex) {
    if (ex.code === 1001) {
      upgradePlanModal?.value.show();
    } else if (ex.code === 1002) {
      subscribeTgChannelModal.value?.show();
    } else {
      errorMessage.value = ex.message;
    }
    if (newContext) {
      imagingStore.removeContext(context.id);
      context.id = null;
    }
    return;
  } finally {
    sendProcess.value = false;
  }

  clear();

  if (newContext) {
    router.push({ name: 'imaging', params: { contextId: context.id } });
  }
};

if (!appStore.isMobile) {
  onMounted(async () => {
    await nextTick();
    promptEl.value?.focus();
  });
}
</script>

<style lang="scss">
.imaging-form{
  position: relative;
  &__error{
    position: absolute;
    width: 100%;
    bottom: 100%;
    margin-bottom: 1em;
  }
  &__options{
    padding: 10px;
    font-size: 12px;
  }
}
</style>
