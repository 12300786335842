<template>
  <span class="nav-label"><slot></slot></span>
</template>

<style lang="scss">
.nav-label {
  display: block;
  color: #999;
  padding: .5em 1em;
}
</style>
