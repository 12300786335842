export const replaceItem = (array, item, cmp) => {
  const index = array.findIndex((c) => cmp(c, item));
  if (index !== -1) {
    array[index] = item;
  }
};

export const replaceOrPushItem = (array, item, cmp) => {
  const index = array.findIndex((c) => cmp(c, item));
  if (index !== -1) {
    array[index] = item;
    return;
  }
  array.push(item);
};

export const removeItem = (array, item) => {
  const index = typeof item === 'function' ? array.findIndex((c) => item(c)) : array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
};

export const replaceOrInsertItemAsc = (array, item, cmp) => {
  for (let i = array.length - 1; i >= 0; i -= 1) {
    const compareResult = cmp(array[i], item);
    if (compareResult === 0) {
      array[i] = item;
      return;
    }
    if (compareResult < 0) {
      array.splice(i + 1, 0, item);
      return;
    }
  }
  array.unshift(item);
};
