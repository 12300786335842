import {
  nextTick, ref, shallowRef, readonly, shallowReadonly,
} from 'vue';
import useOwner from '@/composables/owner';
import { objectMap } from '@/utils/object';
import { getElementPositionRelative } from '@/utils/fixed-position';
import { useWindowClick, useWindowResize } from '@/composables/event-listener';

export function usePopupDefaultProps() {
  return {
    position: { type: String, default: 'bottom left' },
    origin: { type: String, default: 'auto' },
    margin: { type: Number, default: 0 },
    horizontalMargin: { type: Number, default: 0 },
    verticalMargin: { type: Number, default: 0 },
  };
}

export function usePopup(popupElement, props) {
  const { setOwner, ownsElement } = useOwner();
  const triggerElement = shallowRef(null);
  const targetElement = shallowRef(null);
  const shown = ref(false);
  const styles = shallowRef(null);
  const context = shallowRef(null);

  const positionToOriginProp = (start, end) => {
    const { position } = props;
    if (position.startsWith(start)) {
      return end;
    }
    if (position.startsWith(end)) {
      return start;
    }
    if (position.includes(start)) {
      return start;
    }
    if (position.includes(end)) {
      return end;
    }
    return '';
  };

  const getPopupOrigin = () => {
    const { origin } = props;
    if (origin && origin !== 'auto') {
      return origin;
    }
    return `${positionToOriginProp('left', 'right')} ${positionToOriginProp('top', 'bottom')}`.trim();
  };

  const placePopup = () => {
    styles.value = objectMap(getElementPositionRelative(
      popupElement.value,
      targetElement.value,
      getPopupOrigin(),
      props.position,
      props.horizontalMargin,
      props.verticalMargin,
    ), (v) => `${v}px`);
  };

  const showPopup = (event, { owner, target, context: showContext } = {}) => {
    setOwner(owner);
    triggerElement.value = event?.currentTarget;
    targetElement.value = target ?? event?.currentTarget;
    shown.value = true;
    context.value = showContext;
    nextTick(placePopup);
  };

  const hidePopup = () => {
    shown.value = false;
  };

  const togglePopup = (event, options) => {
    if (shown.value) {
      hidePopup();
    } else {
      showPopup(event, options);
    }
  };

  const hideByOutsideClick = (event) => {
    const { target } = event;
    if (!shown.value) {
      return;
    }
    if (triggerElement.value.contains(target)) {
      return;
    }
    if (ownsElement(target)) {
      return;
    }
    hidePopup();
  };

  useWindowResize(hidePopup);
  useWindowClick(hideByOutsideClick);

  return {
    popupShown: readonly(shown),
    popupStyles: readonly(styles),
    popupContext: shallowReadonly(context),
    showPopup,
    hidePopup,
    togglePopup,
  };
}
