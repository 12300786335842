const grow = (el) => {
  el.style.height = '';
  const lineHeight = parseFloat(getComputedStyle(el).lineHeight, 10);
  const rowCount = Math.max(Math.round(el.scrollHeight / lineHeight), 1);
  el.style.height = `${lineHeight * rowCount}px`;
};

const onInput = (e) => {
  grow(e.currentTarget);
};

const watchElements = [];

window.addEventListener('resize', () => {
  watchElements.forEach(grow);
});

export default {
  mounted: (el) => {
    el.addEventListener('input', onInput);
    grow(el);
    watchElements.push(el);
  },
  updated: (el) => {
    grow(el);
  },
  unmounted: (el) => {
    el.removeEventListener('input', onInput);
    const idx = watchElements.findIndex((e) => e === el);
    if (idx !== -1) {
      watchElements.splice(idx, 1);
    }
  },
};

// export default (app) => {
//   app.directive('autoheight', autoheight);
// };
