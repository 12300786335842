export const validate = (template) => {
  if (template.trim() === '') {
    throw new Error('Шаблон не может быть пустым.');
  }
  const extraCount = template.match(/{{.*?}}/g)?.length || 0;
  if (extraCount > 2) {
    throw new Error('В шаблоне обнаружено более двух ссылок на дополнительные поля.');
  }
  const mainCount = template.match(/\[\[.*?]]/g)?.length || 0;
  if (mainCount > 1) {
    throw new Error('В шаблоне обнаружены множественные ссылки на основное поле.');
  }
  if (mainCount === 0 && extraCount > 0) {
    throw new Error('В шаблоне используются ссылки на дополнительные поля, но нет ссылки на основное поле.');
  }
  return true;
};

export const extractFields = (template) => {
  let match = /\[\[(.*?)]]/g.exec(template);

  const fields = {
    main: match ? match[1] : false,
    extra: [],
  };

  const reExtra = /{{(.*?)}}/g;
  match = reExtra.exec(template);
  while (match) {
    fields.extra.push(match[1]);
    match = reExtra.exec(template);
  }

  return fields;
};

export const toMessage = (template, main, extra) => {
  const message = template.replace(/\[\[.*?]]/, main);
  let extraIndex = -1;
  return message.replace(/{{.*?}}/g, () => {
    extraIndex += 1;
    return extra.length > extraIndex ? extra[extraIndex] : '';
  });
};
