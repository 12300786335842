<template>
  <svg width="1em" height="1em" viewBox="0 0 32 32"
       fill="none"
       stroke="currentColor"
       stroke-linecap="round"
       stroke-linejoin="round"
       stroke-width="2px"
       xmlns="http://www.w3.org/2000/svg">
    <g>
      <line x1="16" x2="7" y1="20.5" y2="11.5"/>
      <line x1="25" x2="16" y1="11.5" y2="20.5"/>
    </g>
  </svg>
</template>
