import MarkdownIt from 'markdown-it';
import clipboard from '@/utils/clipboard';
// katex
import katex from 'katex';
import texmath from 'markdown-it-texmath';
import 'katex/dist/katex.css';
import '@/assets/katex.scss';
// highlight
import hljs from 'highlight.js';
import 'highlight.js/styles/agate.css';
import '@/assets/markdown-content.scss';
import markdownitTableWrap from 'markdown-it-table-wrap';

window.MDCopy = (el) => {
  clipboard.writeText(el.nextSibling.innerText);
};

hljs.registerAliases('vue', { languageName: 'javascript' });
const md = new MarkdownIt('default', {
  breaks: true,
  highlight: (code, language) => {
    let html = null;
    if (language && hljs.getLanguage(language)) {
      try {
        html = hljs.highlight(code, { language, ignoreIllegals: true }).value.trim();
      } catch (_) {
        //
      }
    }
    const originalCode = md.utils.escapeHtml(code);
    const copy = clipboard.supports
      // ? `<span class="md-code__copy" onclick="MDCopy(this)"></span><span class="md-code__src" style="display: none;">${originalCode}</span>`
      ? '<span class="md-code__copy" onclick="MDCopy(this)"></span>'
      : '';
    return `<pre class="md-code">${copy}<code class="hljs">${(html || originalCode)}</code></pre>`;
  },
});
md.disable('hr');
md.use(texmath, {
  engine: katex,
  delimiters: 'brackets',
});
md.use(markdownitTableWrap);

export default (src) => md.render(src);
