<template>
<component :is="iconComponent" />
</template>

<script setup>
import {
  ref, defineProps, watchEffect, capitalize, camelize,
} from 'vue';

const props = defineProps({
  icon: String,
});

const iconComponent = ref(null);
watchEffect(async () => {
  iconComponent.value = props.icon
    ? (await import(`@/components/icons/${capitalize(camelize(props.icon))}Icon.vue`)).default
    : null;
});
</script>
