<template>
  <div class="chat-new-chat">
    <h1 class="text-center">Как использовать умный чат</h1>
    <StepList class="mt-lg">
      <StepListItem v-for="(step) in steps" :key="step" :title="step.title">
        <div class="shadow-text text-justify" v-html="step.description" />
      </StepListItem>
    </StepList>
    <div class="new-chat-here">
      <ArrowLeftDownIcon />
      <div>Воспользуйтесь готовым шаблоном запроса или создайте свой!</div>
    </div>
  </div>
</template>

<script setup>
import StepList from '@/components/StepList.vue';
import StepListItem from '@/components/StepListItem.vue';
import ArrowLeftDownIcon from '@/components/icons/ArrowLeftDownIcon.vue';

const steps = [
  { title: 'Определите цель запроса к нейросети', description: 'Перед тем, как задать вопрос нейросети, необходимо определить желаемый идеальный результат. Это поможет вам конкретнее сформулировать свой запрос.' },
  { title: 'Правильно сформулируйте вопрос/задачу', description: 'Задавайте открытые вопросы, которые побуждают искусственный интеллект отвечать более подробно, в то время как закрытые вопросы позволяют получить краткие и конкретные ответы. Избегайте двусмысленных или неопределенных понятий.' },
  { title: 'Задайте контекст', description: 'Если вы даете нейросети узконаправленную задачу, то предоставьте информацию, которую нейросеть будет использовать для генерации ответа. Включение контекста в запрос позволяет получить более релевантный результат. Если вы ищете информацию по конкретной теме, включите в свой запрос ключевые слова, связанные с этой темой.' },
  { title: 'Опишите детали задачи', description: 'Укажите подробности своей задачи, такие как объем, стиль, формат ответа. Если вам нужен список или таблица, то укажите это. Примените фильтры, если нужно исключить какую-то информацию. При необходимости уточните целевую аудиторию, для кого будет предназначен полученный результат от нейросети.' },
  { title: 'Используйте вспомогательные фразы', description: 'Используйте магические фразы: «Напишите так, будто вы эксперт по данной теме», «Ответье так, будто вы эксперт-маркетолог», «Продумайте свой ответ, шаг за шагом» и другие, чтобы получить лучший результат.' },
  { title: 'Учитесь работать с нейросетями', description: 'Подпишитесь на наш <a href="https://t.me/GoGptRu" target="_blank" class="link">Telegram-канал GoGptRu</a>. Там мы делимся лайфхаками и полезными фишками. Публикуем шаблоны промтов (запросов) и примеры использования. Учим работать с GPT и нейросетями.' },
];
</script>

<style lang="scss">
.new-chat-here{
  display: flex;
  align-items: end;
  gap: .2em;
  margin: 1.5em 0 -1em .8em;
  svg{
    font-size: 1.925em;
    flex-shrink: 0;
  }
  div{
    font-size: 1.2em;
    padding-bottom: .8em;
  }
}
</style>
