<template>
  <div class="model-select">
    <Choise
      :model-value="selectedModel"
      :items="chatStore.models.filter((m) => !m.isHidden)"
      :item-key="(m) => m.id"
      :item-label="(m) => m.title"
      :item-icon="(m) => m.provider"
      :pt="{ menu: { position: 'top left', verticalMargin: 10 }}"
      @update:model-value="selectModel($event)">
    </Choise>

    <UpgradePlan ref="upgradePlanModal" :model="modelToUpgrade" />
    <WarningAdvancedChatModel ref="warningAdvancedChatModelModal" @cancel="resetModel(peviousModel)" :model="selectedModel" />
  </div>
</template>

<script setup>
import {
  defineProps, defineEmits, ref, watch, nextTick, toRaw,
} from 'vue';
import useAccountStore from '@/store/account';
import useChatStore from '@/store/chat';
import WarningAdvancedChatModel from '@/components/modals/WarningAdvancedChatModel.vue';
import UpgradePlan from '@/components/modals/UpgradePlan.vue';
import Choise from '@/components/Choise.vue';

const props = defineProps({
  model: Number,
});

const emit = defineEmits([
  'update:model',
]);

const accountStore = useAccountStore();
const chatStore = useChatStore();

const selectedModel = ref(null);
const peviousModel = ref(null);
const modelToUpgrade = ref(null);

const findVisionModel = () => chatStore.models.find((m) => m.isVision && !m.isHidden) ?? null;

watch(() => props.model, (newModel) => {
  selectedModel.value = chatStore.models.find((m) => m.id === newModel) ?? null;
}, { immediate: true });

const upgradePlanModal = ref(null);
const warningAdvancedChatModelModal = ref(null);

const resetModel = async (m) => {
  m ??= selectedModel.value;
  selectedModel.value = null;
  await nextTick();
  selectedModel.value = m;
};

const selectModel = (m) => {
  m ??= chatStore.models[0];
  if (selectedModel.value.id === m.id) {
    return;
  }
  if (m.cost > 1) {
    const { account } = accountStore;
    if (!account?.supportsAdvancedChatModels) {
      modelToUpgrade.value = m;
      upgradePlanModal.value?.show();
      resetModel();
      return;
    }
    if (!account?.advancedChatModelWarningDisabled) {
      warningAdvancedChatModelModal.value?.show();
    }
  }
  peviousModel.value = selectedModel.value;
  selectedModel.value = m;
  emit('update:model', m.id);
};

const selectVisionModel = () => {
  const { isHidden, isVision } = selectedModel.value;
  if (isVision && !isHidden) {
    return;
  }
  selectModel(findVisionModel());
};

defineExpose({
  selectVisionModel,
});
</script>

<style lang="scss">
</style>
