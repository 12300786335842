import { onMounted, onUnmounted } from 'vue';

export function useEventListener(target, event, callback, options) {
  onMounted(() => target.addEventListener(event, callback, options));
  onUnmounted(() => target.removeEventListener(event, callback, options));
}

export function useWindowResize(callback) {
  useEventListener(window, 'resize', callback, true);
}

export function useWindowClick(callback) {
  useEventListener(window, 'click', callback, true);
}
