export default (config = {}) => new Promise((resolve, reject) => {
  const inputElement = document.createElement('input');
  inputElement.type = 'file';

  if (config.multiple) {
    inputElement.setAttribute('multiple', config.multiple);
  }

  if (config.accept) {
    inputElement.setAttribute('accept', config.accept);
  }

  inputElement.style.display = 'none';

  document.body.appendChild(inputElement);
  const removeInputElement = () => {
    if (document.body.contains(inputElement)) {
      document.body.removeChild(inputElement);
    }
  };
  inputElement.onchange = () => {
    removeInputElement();
    resolve(inputElement.files);
  };

  inputElement.click();

  const onWindowFocus = () => {
    removeInputElement();
    window.removeEventListener('focus', onWindowFocus, true);
    setTimeout(() => {
      if (!inputElement.files.length) {
        reject(new Error('cancel'));
      }
    }, 500);
  };
  window.addEventListener('focus', onWindowFocus, true);
});
