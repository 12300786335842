<template>
  <div class="imaging-form-options">
    <div class="imaging-form-options__group">
      <div class="imaging-form-options__option">
        <span>Режим дополнения</span>
        <Toggle :checked="appendMode" @update:checked="$emit('update:appendMode', $event)" />
      </div>
    </div>
    <div class="imaging-form-options__group">
      <div class="imaging-form-options__option">
        <span>Модель</span>
        <Choise
          :model-value="model"
          :items="imagingStore.models.filter((m) => !m.isHidden)"
          :item-key="(m) => m.id"
          :item-label="(m) => m.title"
          :item-icon="(m) => m.provider"
          @update:model-value="$emit('update:presetId', imagingStore.presets.find((p) => p.modelId === $event.id)?.id)"
          :pt="{ menu: choiseMenuPt }"
        />
      </div>
      <div class="imaging-form-options__option" v-if="model">
          <span>Пресет</span>
          <Choise
            :model-value="preset"
            :items="imagingStore.presets.filter((p) => p.modelId === model?.id)"
            :item-key="(m) => m.id"
            :item-label="(m) => m.title"
            :item-icon="() => 'gear'"
            @update:model-value="$emit('update:presetId', $event.id)"
            :pt="{ menu: choiseMenuPt }"
          />
        </div>
    </div>
    <div class="imaging-form-options__group">
      <div class="imaging-form-options__option">
        <span>Стоимость</span>
        <strong>~{{preset?.cost ?? 0}}</strong>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed, defineProps,
} from 'vue';
import useImagingStore from '@/store/imaging';
import Toggle from '@/components/Toggle.vue';
import Choise from '@/components/Choise.vue';

const props = defineProps({
  presetId: Number,
  appendMode: Boolean,
});
defineEmits([
  'update:presetId',
  'update:appendMode',
]);
const choiseMenuPt = { position: 'top left', verticalMargin: 10 };
const imagingStore = useImagingStore();

const preset = computed(() => imagingStore.presets.find((p) => p.id === props.presetId));
const model = computed(() => imagingStore.models.find((m) => m.id === preset.value?.modelId));
</script>

<style lang="scss">
.imaging-form-options{
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  &__group{
    display: flex;
    gap: 1em;
    &:last-child{
      margin-left: auto;
    }
    &:nth-child(2){
      width: 100%;
      order: 1;
    }
  }
  &__option{
    display: flex;
    align-items: center;
    gap: .5em;
  }
}
@media (min-width: 640px) {
  .imaging-form-options{
    flex-wrap: nowrap;
    &__group:nth-child(2) {
      width: auto;
      order: 0;
    }
  }
}
</style>
