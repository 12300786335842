<template>
  <div class="loader">
    <div class="loader__inner">
      <div></div>
    </div>
  </div>
</template>

<style lang="scss">

@keyframes loader-animation{
  0% {
    transform: rotate(0deg)
  }
  50% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.loader {
  width: 1em;
  height: 1em;
  display: inline-block;
  overflow: hidden;

  &__inner {
    width: 100%;
    height: 0;
    padding-top: 100%;
    position: relative;
    overflow: hidden;

    div {
      position: absolute;
      animation: loader-animation 1s linear infinite;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 50%;
      border: solid .15em;
      border-left-color: transparent;
      border-top-color: transparent;
      box-sizing: border-box;
    }
  }
}
</style>
