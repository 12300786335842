<template>
  <div class="chat-links">
        <span class="link" @click="$emit('regenerate')" v-if="regenerate">
          <span>{{ regenerateText }}</span>
          <span v-if="regenerateTimeout > 0"> (через {{ regenerateTimeout }} сек)</span>
        </span>
    <router-link class="link" to="/payment" v-if="upgrade">{{ upgradeText }}</router-link>
  </div>
</template>

<script setup>
import {
  defineEmits, defineExpose, ref, watch,
} from 'vue';

const emit = defineEmits(['regenerate']);

defineProps({
  regenerate: Boolean,
  regenerateText: { type: String, default: 'обновить ответ' },
  upgrade: Boolean,
  upgradeText: { type: String, default: 'повысить тариф' },
});

let regenerateTimeoutId = null;
const regenerateTimeout = ref(-1);

watch(regenerateTimeout, (newValue, oldValue) => {
  clearTimeout(regenerateTimeoutId);
  if (newValue > 0) {
    regenerateTimeoutId = setTimeout(() => {
      regenerateTimeout.value -= 1;
    }, 1000);
  } else if (newValue === 0 && oldValue > 0) {
    emit('regenerate');
  }
});

const setRegenerateTimeout = (timeout) => {
  if (timeout < 0) {
    clearTimeout(regenerateTimeoutId);
  }
  regenerateTimeout.value = timeout;
};

defineExpose({
  setRegenerateTimeout,
});
</script>

<style lang="scss">
.chat-links{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
  flex-direction: row;
  align-items: center;
  .link{
    padding: .25em 1em;
    box-shadow: 0 0 .5em rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    background: #fff;
  }
}
</style>
