import axios from 'axios';

let useAuthStore = () => null;
export const setAuthStore = (use) => {
  useAuthStore = use;
};

const httpClient = axios.create({
  baseURL: `${process.env.VUE_APP_HOST}/api/`,
});

httpClient.interceptors.request.use((config) => {
  const authStore = useAuthStore();
  if (authStore?.token) {
    config.headers['X-Auth-Token'] = authStore.token;
  }
  return config;
});

httpClient.interceptors.response.use(
  async (response) => {
    const { data } = response;
    if (data.code !== 0) {
      const authStore = useAuthStore();
      if (data.code === 401 && authStore) {
        await authStore.refreshToken();
        if (authStore.token) {
          return httpClient.request(response.config);
        }
      }
      return Promise.reject(data);
    }
    return data.data;
  },
  (error) => {
    const dataError = { code: 1, message: `Ошибка сети: ${error.message}`, data: error };
    return Promise.reject(dataError);
  },
);

export default httpClient;
