import {
  onMounted, onUnmounted, provide, getCurrentInstance,
} from 'vue';
import EventBus from '@/utils/event-bus';

const ownerBus = new EventBus();
const containsEventKey = Symbol('contains-element');

export default function useOwner() {
  const instance = getCurrentInstance();
  const ownerKey = Symbol('');
  let ownerInstance = instance;

  provide(ownerKey, true);

  const containsElement = (target) => {
    const { el, children } = instance.subTree;
    if (el.contains(target)) {
      return true;
    }
    return Array.isArray(children) && children.some((child) => child.el.contains(target));
  };

  const onContainsElement = (event) => (event.ownerKey in ownerInstance.provides)
    && containsElement(event.element);

  onMounted(() => ownerBus.on(containsEventKey, onContainsElement));
  onUnmounted(() => ownerBus.off(containsEventKey, onContainsElement));

  return {
    setOwner(owner) {
      ownerInstance = owner?.$ ?? instance;
    },
    ownsElement(element) {
      return containsElement(element) || ownerBus.some(containsEventKey, { ownerKey, element });
    },
  };
}
