<template>
  <Modal ref="modalElement">
    <template #header>Вложения</template>
    Для использования вложений вам необходимо <router-link to="/payment" class="link">повысить тариф</router-link>.
    <template #footer="{ hide }">
      <Button type="button" icon="cancel" label="Закрыть" severity="" @click="hide()" />
    </template>
  </Modal>
</template>

<script setup>
import { defineExpose, ref } from 'vue';

import Modal from '@/components/Modal.vue';
import Button from '@/components/Button.vue';

const modalElement = ref(null);

defineExpose({
  show: () => modalElement.value?.show(),
});
</script>
