<template>
  <div class="toolbar">
    <div class="toolbar__start">
      <slot name="start" />
    </div>
    <div class="toolbar__center">
      <slot name="center"><slot></slot></slot>
    </div>
    <div class="toolbar__end">
      <slot name="end" />
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss">
.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1em;

  &__start, &__center, &__end {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  &__center{
    justify-content: center;
  }
  &__end{
    justify-content: end;
  }
}
</style>
