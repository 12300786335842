<template>
  <span class="toggle" :class="{'toggle_checked': isChecked}" @click="toggle()">
    <span class="toggle__track"></span>
  </span>
</template>

<script setup>
import {
  defineProps, defineEmits, ref, watch,
} from 'vue';

const props = defineProps({
  checked: Boolean,
});

const emit = defineEmits(['update:checked']);

const isChecked = ref(props.checked);
watch(() => props.checked, (value) => {
  isChecked.value = value;
});

const toggle = () => {
  isChecked.value = !isChecked.value;
  emit('update:checked', isChecked.value);
};
</script>

<style lang="scss">
  .toggle{
    display: inline-block;
    cursor: pointer;
    transition: background-color .3s ease;
    width: 32px;
    height: 16px;
    flex-shrink: 0;

    &__track{
      width: 100%;
      height: 12px;
      position: relative;
      border-radius: 6px;
      background: #ccc;
      margin: 2px 0;
      display: inline-block;

      &:after{
        content: '';
        display: inline-block;
        position: absolute;
        width: 16px;
        height: 16px;
        left: 0;
        top: -2px;
        border-radius: 8px;
        background: #999;
        transition: left .3s ease;
      }
    }
  }

  .toggle_checked .toggle__track{
    &:after{
      background: #19c37d;
      left: calc(100% - 16px);
    }
  }
</style>
