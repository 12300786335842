<template>
  <Modal ref="modalElement" class="modal-input-youtube"
         @focus="$refs.inputElement.focus()"
         @show="resetForm()">
    <template #header>Ссылка на видео YouTube</template>
    <form @submit.prevent="confirm" class="form form_st_rows">
      <div class="form__field form__row">
        <input ref="inputElement" type="text" class="form-input" placeholder="https://youtu.be/jNQXAC9IVRw" v-model.trim="url" autocomplete="off" />
      </div>
      <div class="form__field form__row" v-if="error">
        <Notice severity="error" :message="error" />
      </div>
      <div class="form__buttons form__row">
        <Button type="submit" icon="confirm">
          Подтвердить
        </Button>
        <Button type="button" icon="cancel" severity="" @click="$refs.modalElement.hide()">
          Закрыть
        </Button>
      </div>
    </form>
  </Modal>
</template>

<script setup>
import {
  defineEmits, ref, defineExpose,
} from 'vue';
import Modal from '@/components/Modal.vue';
import Button from '@/components/Button.vue';
import Notice from '@/components/Notice.vue';

const emit = defineEmits([
  'confirm',
]);
//
const modalElement = ref(null);
const error = ref(null);
const url = ref(null);
//
const resetForm = () => {
  error.value = '';
  url.value = '';
};

const confirm = () => {
  if (!/(\b(youtube\.com|youtu\.be))/i.test(url.value)) {
    error.value = 'Введите корректную ссылку на YouTube видео';
    return;
  }
  emit('confirm', { url: url.value });
  modalElement.value?.hide();
};

defineExpose({
  show: () => modalElement.value?.show(),
});
</script>

<style lang="scss">
.modal-input-youtube .modal__window{
  width: 100%;
  max-width: 400px;
}
</style>
