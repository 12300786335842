<template>
  <Modal ref="modalElement" class="modal-warning-advanced-chat-model">
    <template #header>{{model.title}}</template>
    <div>
      Вы переключились на модель {{model.title}}!
      <p>{{model.description}}</p>
      <p>
        Это модель более совершенная, но расход GoCoin-ов будет увеличен в
        <strong>{{model.cost}} {{plural(model.cost, ['раз', 'раза', 'раз'])}}</strong>.
      </p>
    </div>
    <div class="mt-sm iflex ai-center gap-sm">
      <Toggle v-model:checked="advancedChatModelWarningDisabled" />
      <span>больше не показывать</span>
    </div>
    <template #footer="{ hide }">
      <div class="button-list">
        <Button type="button" icon="confirm" label="Хорошо" @click="confirm(); hide()" />
        <Button type="button" icon="cancel" label="Отменить" severity="" @click="$emit('cancel'); hide()" />
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { defineExpose, defineEmits, ref } from 'vue';
import plural from '@aloskutov/plural-ru';
import useAccountStore from '@/store/account';
import Modal from '@/components/Modal.vue';
import Button from '@/components/Button.vue';
import Toggle from '@/components/Toggle.vue';

defineEmits(['cancel']);
defineProps({
  model: Object,
});
const accountStore = useAccountStore();
const modalElement = ref(null);
const advancedChatModelWarningDisabled = ref(accountStore.account?.advancedChatModelWarningDisabled);

const confirm = () => {
  accountStore.save({ advancedChatModelWarningDisabled: advancedChatModelWarningDisabled.value });
};

defineExpose({
  show: () => modalElement.value?.show(),
});
</script>

<style>
.modal-warning-advanced-chat-model .modal__window {
  max-width: 600px;
}
</style>
