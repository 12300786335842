import {
  inject, readonly, ref,
} from 'vue';

const sidebarPortalKey = Symbol('');

function createSidebarPortal() {
  const sidebarPortalEl = ref(null);

  const sidebarPortal = {
    element: readonly(sidebarPortalEl),
    registerElement(el) {
      sidebarPortalEl.value = el;
    },
    install(app) {
      app.provide(sidebarPortalKey, sidebarPortal);
    },
  };

  return sidebarPortal;
}

function useSidebarPortal() {
  return inject(sidebarPortalKey);
}

export { createSidebarPortal, useSidebarPortal };
