<template>
  <div class="template-edit">
    <div class="form form_st_rows">
      <div class="form__field form__row">
        <span class="form-label">Категория</span>
        <TagList
          :items="categories"
          :item-key="(i) => i.id"
          :item-label="(i) => i.title"
          :placeholder="isAdmin ? '' : 'выберите категорию'"
          :new-item="isAdmin"
          new-placeholder="новая категория"
          :menu-button="allCategories.length"
          @new-item="newCategory"
          @remove-item="categoryIds.delete($event.id)"
        >
          <template #menu>
            <MenuItem
              v-for="c in allCategories"
              :key="c.id"
              :label="c.title"
              checkbox
              :checked="categoryIds.has(c.id)"
              @click="categoryIds.has(c.id) ? categoryIds.delete(c.id) : categoryIds.add(c.id)"/>
          </template>
        </TagList>
      </div>
      <div class="form__field form__row">
        <span class="form-label">Название</span>
        <input type="text" v-model.trim="tmpl.title"
               placeholder="Перевод текста"
               class="form-input" :class="{'form-input_error': submit && !tmpl.title}">
      </div>
      <div class="form__field form__row">
        <span class="form-label">Описание (опционально)</span>
        <textarea v-model="tmpl.description"
                  placeholder="Перевод текста на любой язык"
                  class="form-area form-area_sm"></textarea>
      </div>
      <div class="form__field form__row">
        <span class="form-label">Теги</span>
        <TagList
          :items="tags"
          :item-key="(i) => i.id"
          :item-label="(i) => i.label"
          @remove-item="removeTag"
          @new-item="newTag" />
      </div>
      <div class="form__field form__row">
        <span class="form-label">Содержимое шаблона</span>
        <textarea v-model.trim="tmpl.template"
                  :placeholder="'Переведи на {{язык перевода}} текст: [[текст для перевода]]'"
                  class="form-area form-area_lg" :class="{'form-area_error': submit && templateError}"></textarea>
        <Notice severity="error" class="form-hint mt-sm" v-if="submit && templateError">
          {{templateError}}
        </Notice>
        <Notice class="form-hint mt-sm">
          Для использования значения из <strong>основного поля</strong> используйте квадратные скобки <strong v-pre>[[]]</strong>, внутри скобок можете указать подсказку <strong v-pre>[[Текст для перевода]]</strong>,
          еще вам доступно два <strong>дополнительных поля</strong>, для их использования вместо квадратных скобок используйте фигруные <strong v-pre>{{}}</strong>.
        </Notice>
      </div>
      <div class="form__field form__row">
        <div class="form-toggle">
          <Toggle v-model:checked="tmpl.useContext" />
          <span class="form-label">Использовать контекст для формирования ответа</span>
        </div>
      </div>
      <div class="form__field form__row">
        <div class="form-toggle">
          <Toggle v-model:checked="tmpl.ignore" />
          <span class="form-label">Не использовать далее в контексте</span>
        </div>
      </div>
      <div class="form__field form__row" v-if="isAdmin">
        <div class="form-toggle">
          <Toggle v-model:checked="tmpl.public" />
          <span class="form-label">Опубликовать в каталоге</span>
        </div>
      </div>
      <div class="form__error form__row" v-if="saveError">
        <Notice severity="error" :message="saveError" />
      </div>
      <div class="form__buttons form__row">
        <Button icon="confirm" @click="save()" :is-busy="process">
          Сохранить
        </Button>
        <Button icon="cancel" severity="" @click="$emit('close')" v-if="tmpl.id">
          Отмена
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  defineProps, defineEmits, reactive, ref, computed,
} from 'vue';
import api from '@/utils/api';
import useAuthStore from '@/store/auth';
import useTemplateStore from '@/store/template';
import { validate as validateTemplate } from '@/utils/template';
import Toggle from '@/components/Toggle.vue';
import Button from '@/components/Button.vue';
import Notice from '@/components/Notice.vue';
import TagList from '@/components/TagList.vue';
import MenuItem from '@/components/MenuItem.vue';

const props = defineProps({
  template: Object,
});

const emit = defineEmits(['close']);

const authStore = useAuthStore();
const templateStore = useTemplateStore();
const isAdmin = computed(() => authStore.user.isAdmin);

const tmpl = reactive({
  id: null,
  public: false,
  ignore: false,
  useContext: true,
  title: '',
  description: '',
  template: '',
  categoryIds: [],
  tags: null,
  ...(props.template || {}),
});

const templateError = computed(() => {
  try {
    validateTemplate(tmpl.template);
  } catch (e) {
    return e.message;
  }
  return '';
});

const submit = ref(false);
const saveError = ref('');
const process = ref(false);

const tagsRemoveItem = (items, id) => {
  // eslint-disable-next-line no-bitwise
  items.splice(items.findIndex((tag) => tag.id === id) >>> 0, 1);
};

const categoryIds = ref(new Set(tmpl.categoryIds));
const allCategories = computed(() => templateStore.categories ?? []);
const categories = computed(() => templateStore.categories?.filter((c) => categoryIds.value.has(c.id)) ?? []);
templateStore.loadCategories(true);

const newCategory = async (title) => {
  categoryIds.value.add(await templateStore.saveCategory(null, { title }));
};

const tags = ref([]);
let lastTagId = 1;
// eslint-disable-next-line no-plusplus
const newTag = (label) => tags?.value.push({ id: lastTagId++, label });
const removeTag = ({ id }) => tagsRemoveItem(tags.value, id);
tmpl.tags?.split(/[\r\n]+/).forEach(newTag);

const save = async () => {
  if (process.value) {
    return;
  }
  submit.value = true;
  saveError.value = '';
  if (!tmpl.title || templateError.value) {
    saveError.value = 'Исправьте ошибки в полях и повторите попытку снова.';
    return;
  }
  process.value = true;
  try {
    await api.post('template/save', {
      id: tmpl.id,
      public: tmpl.public,
      ignore: tmpl.ignore,
      useContext: tmpl.useContext,
      title: tmpl.title,
      description: tmpl.description,
      template: tmpl.template,
      categoryIds: Array.from(categoryIds.value),
      tags: tags.value.map((tag) => tag.label).join('\n'),
    });
    emit('close');
  } catch (e) {
    saveError.value = e.message;
  } finally {
    process.value = false;
  }
};

</script>

<style lang="scss">
@import "@/assets/variables";

.template-edit{
  max-width: $contentNarrowWidth;
  margin: 0 auto;
}
</style>
