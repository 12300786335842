import { reactive, computed } from 'vue';
import { defineStore } from 'pinia';
import moment from 'moment';
import api from '@/utils/api';

export default defineStore('account', () => {
  const account = reactive({});

  const unitsLimit = computed(() => account.unitsLimit || 0);
  const planExpiresAt = computed(() => (account.planExpiresAt ? moment.unix(account.planExpiresAt) : null));

  const update = async () => {
    Object.assign(account, await api.get('account/get'));
  };

  const save = async (fields) => {
    await api.post('account/save', fields);
    update().then();
  };

  const resetApiKey = async () => {
    await api.post('account/reset-api-key');
    update().then();
  };

  const subscriberStatus = () => api.get('account/subscriber-status');

  return {
    account,
    update,
    save,
    resetApiKey,
    subscriberStatus,
    unitsLimit,
    planExpiresAt,
  };
});
