<template>
  <slot class="webapp"></slot>
</template>

<script setup>
</script>

<style lang="scss">
.webapp {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
</style>
