<template>
  <div ref="asideEl" class="sidebar-portal" v-pre></div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useSidebarPortal } from '@/utils/sidebar-portal';

const emit = defineEmits(['changed']);

const sidebarPortal = useSidebarPortal();
const asideEl = ref(null);
const sidebarObserver = new MutationObserver(() => {
  const el = asideEl.value;
  if (!el) {
    return;
  }

  const hasContent = [].some.call(
    el.childNodes,
    (node) => node.nodeType !== Node.COMMENT_NODE
      && (node.nodeType !== Node.TEXT_NODE || node.nodeValue !== ''),
  );
  emit('changed', { hasContent });
});

watch(asideEl, (el) => {
  sidebarPortal.registerElement(el);
  sidebarObserver.disconnect();
  if (el) {
    sidebarObserver.observe(el, { childList: true });
  }
});

</script>
