export default class EventBus {
  constructor() {
    this.handlers = new Map();
  }

  on(type, handler) {
    if (!this.handlers.has(type)) {
      this.handlers.set(type, []);
    }
    this.handlers.get(type).push(handler);
  }

  off(type, handler) {
    const handlers = this.handlers.get(type);
    if (handlers) {
      // eslint-disable-next-line no-bitwise
      handlers.splice(handlers.indexOf(handler) >>> 0, 1);
    }
  }

  emit(type, event) {
    this.handlers.get(type)?.map((handler) => handler(event));
  }

  some(type, event) {
    return this.handlers.get(type)?.some((handler) => handler(event)) ?? false;
  }
}
