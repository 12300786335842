<template>
  <component :is="layout" v-slot="slotProps">
    <slot v-bind="slotProps"></slot>
  </component>
</template>

<script setup>
import { computed } from 'vue';
import useAuthStore from '@/store/auth';
import Dashboard from '@/layouts/Dashboard.vue';
import Public from '@/layouts/Public.vue';

const authStore = useAuthStore();
const layout = computed(() => (authStore.token ? Dashboard : Public));
</script>
