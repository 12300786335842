<template>
<div class="imaging" :class="{'imaging_new': !imageStore.context}">
  <div class="imaging__wrapper">
    <ImagingImages class="imaging__images p-md" />
    <ChatLinks :regenerate="canRegenerate" regenerate-text="ещё вариант" @regenerate="onRegenerate" />
    <div class="imaging__form ph-md mt-md">
      <ImagingForm class="content content_narrow mh-auto" />
    </div>
  </div>
</div>
</template>

<script setup>
// noinspection ES6UnusedImports
import { computed } from 'vue';
import useImagingStore from '@/store/imaging';
import ImagingForm from '@/components/ImagingForm.vue';
import ImagingImages from '@/components/ImagingImages.vue';
import ChatLinks from '@/components/ChatLinks.vue';

const imageStore = useImagingStore();
const isPending = computed(() => imageStore.images.some((i) => i.pending));
const lastImage = computed(() => imageStore.images.slice(-1)[0]);
const canRegenerate = computed(() => !isPending.value && !!lastImage.value);

const onRegenerate = () => {
  imageStore.redrawImage(lastImage.value.id).then(() => null);
};
</script>

<script>
/* eslint-disable-next-line consistent-return */
const loadArticle = async (to) => {
  const contextId = parseInt(to.params.contextId, 10) || null;
  try {
    await useImagingStore().selectContext(contextId);
  } catch (e) {
    return {
      name: 'not-found',
      query: { url: to.fullPath },
    };
  }
};

export default {
  beforeRouteEnter: loadArticle,
  beforeRouteUpdate: loadArticle,
};
</script>

<style lang="scss">
@import "@/assets/variables";
.imaging {
  &__wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    &:before{
      content: '';
      flex-grow: 1;
      height: 0;
    }
  }
  &__images{
    margin-bottom: 1em;
  }
  &__images .imaging-images__inner{
    max-width: $contentNarrowWidth;
    margin: 0 auto;
  }
  &__form{
    margin-top: 1em;
  }
}
.imaging_new{
  .imaging__wrapper:before{
    display: none;
  }
  .imaging__images{
    flex-grow: 1;
  }
}
</style>
