// eslint-disable-next-line import/prefer-default-export
export const auth = (callback) => {
  window.Telegram.Login.auth(
    {
      bot_id: 6385479623, // GoGptRuBot
      request_access: true,
      lang: 'ru',
    },
    callback,
  );
};
