import { ref } from 'vue';
import { defineStore } from 'pinia';
import api from '@/utils/api';

const store = defineStore('template', () => {
  const categories = ref(null);

  const loadCategories = async (lazy) => {
    if (lazy && categories.value) {
      return;
    }
    categories.value = await api.get('template/category/list');
  };

  const saveCategory = async (id, fields) => {
    const categoryId = await api.post('template/category/save', { ...fields, id });
    await loadCategories();
    return categoryId;
  };

  return {
    loadCategories,
    categories,
    saveCategory,
  };
});

export default store;
