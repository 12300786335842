<template>
  <div class="tag-list">
    <div v-if="!hasItems && ($props.placeholder || $slots.placeholder)" class="tag-list__placeholder">
      <template v-if="$slots.placeholder">
        <div><slot name="placeholder"></slot></div>
      </template>
      <div v-else class="tag-list__empty">
        <span>{{placeholder}}</span>
      </div>
    </div>
    <slot>
      <Tag
        v-for="(item, idx) in items"
        :key="itemKey(item, idx)"
        :label="itemLabel?.(item, idx)"
        :can-close="!readonly"
        @close="$emit('removeItem', item)">
        <slot :item="item" name="item"></slot>
      </Tag>
    </slot>
    <div v-if="!readonly && $props.newItem" class="tag-list__new">
      <input type="text" v-model.trim="newTagText" :placeholder="newPlaceholder" @keydown.enter="newItem" />
      <div v-text="newTagText ? newTagText : newPlaceholder" />
    </div>
    <div v-if="menuButton" class="tag-list__menu" @click="$refs.popupMenuElement.toggle($event)">
      <ChevronDownIcon />
      <PopupMenu ref="popupMenuElement" position="bottom right" :vertical-margin="1">
        <slot name="menu" />
      </PopupMenu>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  defineProps, ref, useSlots, watchEffect,
} from 'vue';
import Tag from '@/components/Tag.vue';
import ChevronDownIcon from '@/components/icons/ChevronDownIcon.vue';
import PopupMenu from '@/components/PopupMenu.vue';

const props = defineProps({
  items: Array,
  itemKey: Function,
  itemLabel: Function,
  readonly: Boolean,
  newItem: { type: Boolean, default: true },
  menuButton: Boolean,
  placeholder: String,
  newPlaceholder: { type: String, default: 'новый тег' },
});

const emit = defineEmits(['removeItem', 'newItem', 'menu']);

const newTagText = ref('');
const newItem = async () => {
  const text = newTagText.value;
  if (!text) {
    return;
  }
  emit('newItem', text);
  newTagText.value = '';
};

const slots = useSlots();
const hasItems = computed(() => !!(props.items?.length || slots.default?.().length));
</script>

<style lang="scss">
.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: .5em;

  &__placeholder{
    display: flex;
    align-items: center;
  }
  &__empty{
    color: #777;
    background: #e8e8e8;
    font-size: .95em;
    align-self: stretch;
    display: flex;
    align-items: center;
    padding: .25em .5em;
    border-radius: 3px;
  }
  &__new{
    min-width: 7em;
    background: #e8e8e8;
    border-radius: 3px;
    overflow: hidden;
    font-size: .95em;
    div, input{
      font-family: inherit;
      font-size: inherit;
    }
    div{
      height: 0;
      opacity: 0;
      white-space: nowrap;
      padding: 0 .75em;
    }
    input{
      align-self: stretch;
      width: 0;
      min-width: 100%;
      box-sizing: border-box;
      border: none;
      outline: none;
      background: transparent;
      padding: .25em .5em;
    }
  }

  &__menu{
    background: #e8e8e8;
    padding: .25em;
    display: grid;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color .3s ease;
    &:hover{
      background-color: #d0d0d0;
    }
  }
}
</style>
