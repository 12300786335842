<template>
  <Modal ref="modalElement" class="modal-warning-large-context">
    <template #header>Большой контекст</template>
    Модель <strong>{{ model.title }}</strong> не способна обрабатывать запросы,
    в которых количество токенов превышает лимит в <strong>{{ numeralFormat(model.contextLimit) }}</strong> {{plural(model.contextLimit, pluralTokens)}}.
    В вашем запросе примерно <strong>{{ numeralFormat(tokens) }}</strong> {{plural(tokens, pluralTokens)}}.
    <p>
      Чтобы уложиться в предел токенов, вы можете включить экономный режим, удалить или игнорировать
      некоторые сообщения, либо перейти на модель, поддерживающую большее количество токенов в контексте.
    </p>
    <template #footer="{ hide }">
      <Button type="button" icon="cancel" label="Закрыть" severity="" @click="hide()" />
    </template>
  </Modal>
</template>

<script setup>
import { computed, defineExpose, ref } from 'vue';
import plural from '@aloskutov/plural-ru';

import Modal from '@/components/Modal.vue';
import Button from '@/components/Button.vue';

const pluralTokens = ['токен', 'токена', 'токенов'];

const modalElement = ref(null);
const model = ref(null);
const cost = ref(null);

const tokens = computed(() => cost.value / model.value.cost);

const show = (context) => {
  model.value = context.model;
  cost.value = context.cost;
  modalElement.value?.show();
};

defineExpose({
  show,
});
</script>

<style lang="scss">
.modal-warning-large-context .modal__window {
  max-width: 600px;
}
</style>
