<template>
  <ScrollPanel ref="scrollEl" class="imaging-images" @scroll="onScroll">
    <div ref="innerEl" class="imaging-images__inner">
      <div class="imaging-images__list" v-if="imagingStore.context">
        <ImagingImage
          class="imaging-images__item"
          v-for="i in imagingStore.images"
          :key="i.id"
          :prompt="i"
          @image-action="imagingStore.actionImage(i.id, $event).then(() => null)"
          @redraw="imagingStore.redrawImage(i.id).then(() => null)"
          @remove="imagingStore.removeImage(i.id)"/>
      </div>
      <ImagingNewChat v-else />
    </div>
  </ScrollPanel>
</template>

<script setup>
import {
  nextTick, onMounted, ref, watch,
} from 'vue';
import useImagingStore from '@/store/imaging';
import ScrollPanel from '@/components/ScrollPanel.vue';
import ImagingImage from '@/components/ImagingImage.vue';
import ImagingNewChat from '@/components/ImagingNewChat.vue';

const imagingStore = useImagingStore();

let autoScroll = true;
let ignoreScroll = false;
const scrollEl = ref(null);
const innerEl = ref(null);

const scrollToBottom = () => {
  if (!imagingStore.context) {
    return;
  }
  ignoreScroll = true;
  scrollEl.value?.scrollToBottom();
  ignoreScroll = false;
};
const resizeObserver = new ResizeObserver(() => autoScroll && scrollToBottom());

const onScroll = (e) => {
  if (ignoreScroll) {
    return;
  }
  const el = e.target;
  setTimeout(() => {
    autoScroll = el.scrollHeight - el.clientHeight - el.scrollTop < 50;
  }, 1000);
};

let imagesCount = 0;
watch(() => imagingStore.images, (list) => {
  if (imagesCount < list.length) {
    scrollToBottom();
  }
  imagesCount = list.length;
}, { deep: true });

watch(innerEl, (nEl, oEl) => {
  if (oEl) {
    resizeObserver.unobserve(oEl);
  }
  if (nEl) {
    resizeObserver.observe(nEl);
  }
});

onMounted(() => {
  nextTick(scrollToBottom);
});
</script>

<style lang="scss">
.imaging-images{
  &__inner{
    width: 100%;
  }
  &__list{
    .imaging-image{
      margin-top: 1.5em;
    }
  }
}
</style>
