<template>
  <span class="nav-separator"></span>
</template>

<style lang="scss">
.nav-separator {
  display: block;
  color: #999;
  padding: .5em 0;

  &:before {
    display: block;
    content: '';
    border-top: solid #4d4d4d 1px;
  }
}
</style>
