<template>
  <div class="image">
    <Loader v-if="loading" />
    <img v-else :alt="alt" :src="src" />
  </div>
</template>

<script setup>
import { defineProps, ref, watch } from 'vue';
import Loader from '@/components/Loader.vue';

const props = defineProps({
  src: String,
  alt: String,
});

const loading = ref(true);

watch(() => props.src, (src) => {
  loading.value = true;
  const callback = () => {
    loading.value = false;
  };
  const img = new Image();
  img.onload = callback;
  img.onerror = callback;
  img.src = src;
}, { immediate: true });
</script>

<style lang="scss">
.image{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
