<template>
  <div>
    <div class="chat-wrapper">
      <Templates v-if="templatesShown"
                 @close="templatesShown=false"
                 @select="selectTemplate($event)"/>
      <div class="chat" :class="{'chat_new': !context}" v-show="!templatesShown">
        <ChatMessages ref="messagesEl"
                      class="chat__messages"
                      :messages="messages"
                      :assistant-typing="generatorState === 'busy'"
                      :context-limit="contextLimit"
                      @generate="generateMessage(0, false, $event)"
                      @edit="editMessage = $event">
          <template #empty>
            <ChatNewChat v-if="!contextId" />
          </template>
        </ChatMessages>

        <ChatLinks ref="chatLinksEl" v-show="isReady && (canRegenerate || canUpgrade)"
                   class="chat__links"
                   :regenerate="canRegenerate"
                   :upgrade="canUpgrade"
                   :upgrade-text="upgradeText"
                   @regenerate="generateMessage(0, false, generatorState === 'ready' ? lastMessage : null)"/>

        <div class="chat__form">
          <div class="chat__form-inner">
            <div class="chat__form-error" v-if="formError">
              <Notice :closable="true" :timeout="5000" @close="formError=''" severity="error">
                {{ formError }}
              </Notice>
            </div>
            <ChatForm ref="form"
                      :can-send-message="!isBusy"
                      v-model:template="template"
                      v-model:message="editMessage"
                      @template="templatesShown = true"
                      @send-before="sendProcess = true; formError = ''"
                      @send-after="sendProcess = false"
                      @error="formError = $event"
                      @new-message="newMessage"/>
          </div>
        </div>
      </div>
      <SubscribeTgChannelModal ref="subscribeTgChannelModal" />
    </div>
  </div>
</template>

<script setup>
import {
  computed, defineProps, nextTick, onMounted, onUnmounted, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import useAccountStore from '@/store/account';
import useChatStore from '@/store/chat';
import ChatForm from '@/components/ChatForm.vue';
import ChatMessages from '@/components/ChatMessages.vue';
import Templates from '@/views/Templates.vue';
import ChatLinks from '@/components/ChatLinks.vue';
import Notice from '@/components/Notice.vue';
import ChatNewChat from '@/components/ChatNewChat.vue';
import api from '@/utils/api';
import asyncDelay from '@/utils/delay';
import SubscribeTgChannelModal from '@/components/modals/SubscribeTgChannel.vue';

const props = defineProps({
  contextId: Number,
});

const router = useRouter();

const accountStore = useAccountStore();
const chatStore = useChatStore();

let isMounted = false;
const isReady = ref(false);

// Template
const templatesShown = ref(false);
const template = ref(null);
const selectTemplate = (t) => {
  templatesShown.value = false;
  template.value = t;
};

const editMessage = ref(null);

// Messages
const context = computed(() => chatStore.context);
const messages = computed(() => chatStore.messages);
const lastMessage = computed(() => messages.value?.slice(-1).pop());
const contextLimit = computed(() => (context.value?.ecoMode ? accountStore.account?.ecoContextLimit : -1));

const form = ref(null);
const formError = ref('');
const sendProcess = ref(false);
const generateProcess = ref(false);
const generatorState = ref('ready');
const isBusy = computed(() => generatorState.value === 'busy' || generateProcess.value || sendProcess.value);
const canRegenerate = computed(() => !isBusy.value && (/pending|error/.test(generatorState.value) || lastMessage.value?.role === 'user'));
const upgradeText = ref(null);
const canUpgrade = computed(() => !!upgradeText.value);

const subscribeTgChannelModal = ref(null);
const chatLinksEl = ref(null);
const setRegenerateTimeout = (timeout) => {
  chatLinksEl.value?.setRegenerateTimeout(timeout);
};

const messagesEl = ref(null);
const scrollMessagesToBottom = () => {
  messagesEl.value?.scrollToBottom();
};

watch(generatorState, (newState) => {
  if (newState === 'busy') {
    nextTick(scrollMessagesToBottom);
  }
});

const loadMessages = async (loadNewMessages) => {
  await chatStore.loadMessages(loadNewMessages);
  scrollMessagesToBottom();
};

const updateGeneratorState = async () => {
  const { state, error } = await api.get(
    'chat/generator/state',
    { params: { contextId: props.contextId } },
  );
  generatorState.value = state;
  formError.value = error;
};

const waitGenerateMessage = async () => {
  generateProcess.value = true;

  await asyncDelay(2000);
  await updateGeneratorState();

  const state = generatorState.value;
  if (state === 'busy') {
    waitGenerateMessage();
    return;
  }
  if (state !== 'error') {
    generatorState.value = 'busy';
    await loadMessages(true);
    generatorState.value = state;
  }
  generateProcess.value = false;
  accountStore.update();
};

const generateMessage = async (delay, retry, replyTo) => {
  if (!isMounted) {
    return;
  }

  if (isBusy.value && !retry) {
    formError.value = 'Дождитесь ответа.';
    return;
  }

  formError.value = '';
  generateProcess.value = true;

  await asyncDelay(delay || 0);
  generatorState.value = 'busy';

  try {
    const parmas = replyTo ? { messageId: replyTo.id } : { contextId: props.contextId };
    await chatStore.generateMessage(parmas);
    waitGenerateMessage();
  } catch ({ code, message, data }) {
    generateProcess.value = false;
    if (code === 1001) {
      subscribeTgChannelModal.value?.show();
      generatorState.value = 'error';
      return;
    }
    if (code === 1002) {
      generateMessage(2000, true);
      return;
    }
    if (code === 1003) {
      upgradeText.value = `получить доступ к модели ${data}`;
    } else if (code === 1004) {
      setRegenerateTimeout(data);
      upgradeText.value = 'ускорить ответы';
    } else if (accountStore.account?.planId === 1) {
      upgradeText.value = 'улучшить качество ответов';
    }
    generatorState.value = 'error';
    formError.value = message;
  }
};

const newMessage = ({ contextId }) => {
  if (!context.value) {
    router.push({ name: 'chat', params: { contextId } });
    return;
  }
  generateMessage(1000);
  scrollMessagesToBottom();
};

const initChat = async () => {
  if (!props.contextId) {
    return;
  }
  scrollMessagesToBottom();
  await loadMessages();
  await updateGeneratorState();
  if (generatorState.value === 'pending') {
    generateMessage();
  }
  if (generatorState.value === 'busy') {
    waitGenerateMessage();
  }
};

onMounted(async () => {
  isMounted = true;
  watch(messages, () => {
    form.value?.updateContextCost();
  }, { deep: true });
  await initChat();
  isReady.value = true;
});

onUnmounted(() => {
  isMounted = false;
});
</script>

<script>
// eslint-disable-next-line consistent-return
const routeGuard = async (to) => {
  const contextId = parseInt(to.params.contextId, 10) || null;
  // noinspection JSUnresolvedReference
  return useChatStore().selectContext(contextId).catch(() => ({
    name: 'not-found',
    query: { url: to.fullPath },
  }));
};

export default {
  beforeRouteEnter: routeGuard,
  beforeRouteUpdate: routeGuard,
};
</script>

<style lang="scss">
@import "@/assets/variables";

.chat-wrapper {
  width: 100%;
  height: 100%;
}

.chat {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  font-size: 16px;

  &:before {
    content: '';
    flex-grow: 1;
    height: 0;
  }

  &__messages, &__form {
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
  }

  &__messages .chat-messages__inner {
    max-width: $contentNarrowWidth;
    margin: 0 auto;
  }

  &__links {
    box-shadow: 0 0 10px 10px #fff;
    z-index: 0;
  }

  &__form {
    flex-shrink: 0;
    padding: 1em 1em 0;
  }

  &__form-inner {
    max-width: $contentNarrowWidth;
    margin: 0 auto;
    width: 100%;
    position: relative;
  }

  &__form-error {
    position: absolute;
    width: 100%;
    bottom: calc(100% + 1em);
    cursor: pointer;
    z-index: 0;
  }
}
.chat_new{
  &:before{
    display: none;
  }
  .chat__messages{
    flex-grow: 1;
  }
}
</style>
