<template>
  <span class="typing">
    <span></span><span></span><span></span>
  </span>
</template>

<style lang="scss">
.typing {
  display: inline-block;

  span {
    margin: 0 .2em;
    display: inline-block;
    font-size: .5em;
    width: 1em;
    height: 1em;
    border-radius: .5em;
    background: #555;

    &:nth-child(1) {
      animation: typing-animation 0.8s 0.1s ease-in infinite;
    }

    &:nth-child(2) {
      animation: typing-animation 0.8s 0.2s ease-in infinite;
    }

    &:nth-child(3) {
      animation: typing-animation 0.8s 0.3s ease-in infinite;
    }
  }
}

@keyframes typing-animation {
  0% {
  }
  50% {
    transform: scale(.5);
    opacity: .5;
  }
  100% {
  }
}
</style>
