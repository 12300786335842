import { auth as tgAuth } from '@/utils/telegram';
import useAuthStore from '@/store/auth';

export default function useTelegramGo() {
  const authStore = useAuthStore();

  return function telegramGo(name) {
    const open = () => window.open(`https://t.me/${name}`, '_blank');

    if (authStore.user.telegramUserId) {
      open();
      return;
    }
    tgAuth(async (authData) => {
      try {
        await authStore.connectTelegram('', authData);
        open();
      } catch (ex) {
        //
      }
    });
  };
}
