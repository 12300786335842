import {
  shallowRef, ref, computed, watch,
} from 'vue';
import { defineStore } from 'pinia';
import api from '@/utils/api';
import delay from '@/utils/delay';
import useAccountSotre from '@/store/account';
import { removeItem, replaceOrInsertItemAsc, replaceOrPushItem } from '@/utils/list';

const store = defineStore('imaging', () => {
  const accountSore = useAccountSotre();
  const models = shallowRef([]);
  const presets = shallowRef([]);
  const contexts = ref([]);
  const selectedContextId = ref(null);
  const context = computed(() => contexts.value.find((i) => i.id === selectedContextId.value) ?? null);
  const images = ref([]);

  const loadPresets = async () => {
    models.value = await api.get('imaging/model/list');
    presets.value = await api.get('imaging/preset/list');
  };

  const insertContext = (ctx) => replaceOrPushItem(contexts.value, ctx, (c, b) => c.id === b.id);

  const loadContexts = async () => {
    contexts.value = await api.get('imaging/context/list');
  };

  const loadContext = async (id) => {
    insertContext(await api.get('imaging/context/get', { params: { id } }));
  };

  const saveContext = async (id, fields) => {
    const newId = await api.post('imaging/context/save', { ...fields, id });
    await loadContext(newId);
    return newId;
  };

  const removeContext = async (id) => {
    removeItem(contexts.value, (c) => c.id === id);
    return api.post('imaging/context/remove', { id }).catch(() => null);
  };

  const selectContext = async (id) => {
    if (id) {
      await loadContext(id);
    }
    images.value = [];
    selectedContextId.value = id;
    // noinspection ES6MissingAwait
    // eslint-disable-next-line no-use-before-define
    loadImages();
  };

  const loadImages = async () => {
    if (!selectedContextId.value) {
      images.value = [];
      return;
    }
    images.value = await api.get('imaging/image/list', { params: { contextId: selectedContextId.value } });
  };

  const insertImage = (img) => {
    if (img.contextId === selectedContextId.value) {
      replaceOrInsertItemAsc(images.value, img, (a, b) => a.id - b.id);
    }
  };

  const loadPrompt = async (id) => {
    insertImage(await api.get('imaging/image/get', { params: { id } }));
  };

  const createImage = async (fields) => {
    loadPrompt(await api.post('imaging/image/create', fields));
    loadContext(fields.contextId).catch(() => null);
  };

  const loadImage = (id) => api.get('imaging/image/get-image', { params: { id } });

  const redrawImage = async (promptId, params) => {
    loadPrompt(await api.post('imaging/image/redraw', { promptId, ...params }));
    loadContext(context.value?.id).catch(() => null);
  };

  const actionImage = async (promptId, {
    id: imageId, type, choice, params,
  }) => {
    loadPrompt(await api.post('imaging/image/action', {
      promptId, imageId, type, choice, ...params,
    }));
    loadContext(context.value?.id).catch(() => null);
  };

  const removeImage = async (id) => {
    await api.post('imaging/image/remove', { id });
    removeItem(images.value, (item) => item.id === id);
  };

  let updatePendingImagesDelayPromise = null;
  const updatePendingImages = async () => {
    const delayPromise = delay(2000);
    updatePendingImagesDelayPromise = delayPromise;
    await delayPromise;
    if (updatePendingImagesDelayPromise !== delayPromise) {
      return;
    }

    const ids = images.value.filter((p) => p.images.some((i) => i.pending)).map((p) => p.id);
    if (!ids.length) {
      return;
    }
    const checkedImages = await api.get('imaging/image/list', { params: { contextId: selectedContextId.value, ids } });
    // let hasComplete = false;
    // checkedImages.forEach((p) => {
    //   if (!p.some.pending) {
    //     insertImage(p);
    //     hasComplete = true;
    //   }
    // });
    checkedImages.forEach((p) => insertImage(p));
    // if (hasComplete) {
    //   accountSore.update();
    // }
    accountSore.update();
    updatePendingImages();
  };

  watch(images, () => {
    updatePendingImages();
  }, { deep: true });

  return {
    models,
    presets,
    contexts,
    context,
    images,
    loadPresets,
    loadContexts,
    saveContext,
    removeContext,
    selectContext,
    createImage,
    loadImage,
    removeImage,
    redrawImage,
    actionImage,
  };
});

export default store;
