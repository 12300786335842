<template>
  <div class="message-menu">
    <Button
      class="message-menu__button"
      icon="popupMenu"
      severity=""
      @click="$refs.popupEl.toggle($event)"/>
    <PopupMenu ref="popupEl" position="bottom right" :vertical-margin="5">
      <MenuItem label="Копировать" icon="copy" v-if="clipboard.supports"
                @click="clipboard.writeText(message.message)"/>
      <MenuItem label="Редактировать" icon="edit"
                @click="$emit('edit', message)" />
      <MenuItem label="Игнорировать" icon="cancel" :checkbox="true" :checked="message.ignore"
                @click="toggleIgnore()"/>
      <MenuItem label="Удалить" icon="remove"
                @click="removeMessage()" />
    </PopupMenu>
  </div>
</template>

<script setup>
import {
  toRaw, defineEmits,
} from 'vue';
import useChatStore from '@/store/chat';
import deferredAction from '@/utils/deferred-action';
import Button from '@/components/Button.vue';
import MenuItem from '@/components/MenuItem.vue';
import clipboard from '@/utils/clipboard';
import PopupMenu from '@/components/PopupMenu.vue';

const props = defineProps({
  message: Object,
});

const emit = defineEmits(['remove', 'generate', 'edit']);

const chatStore = useChatStore();

const toggleIgnore = () => {
  const m = toRaw(props.message);
  chatStore.saveMessage(m.id, { ignore: !m.ignore });
};

const removeMessage = () => {
  emit('remove', deferredAction(5, () => {
    chatStore.removeMessage(props.message.id);
  }));
};
</script>

<style lang="scss">
.message-menu{
  display: inline-block;
  &__button{
    padding: .25em;
    color: #555;
    background-color: rgba(255,255,255,.2);
    border: solid 1px rgba(255,255,255,.3);
    &:hover{
      background-color: rgba(255,255,255,.3);
    }
  }
}
</style>
