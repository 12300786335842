import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import api from '@/utils/api';
import { replaceOrPushItem, replaceOrInsertItemAsc, removeItem } from '@/utils/list';

export default defineStore('chat', () => {
  const messages = ref([]);
  const contexts = ref([]);
  const models = ref([]);

  const selectedContextId = ref(null);
  const context = computed(() => contexts.value.find((i) => i.id === selectedContextId.value) ?? null);

  const insertMessage = (m) => {
    if (m.contextId === selectedContextId.value) {
      replaceOrInsertItemAsc(messages.value, m, (a, b) => a.id - b.id);
    }
  };

  const loadMessages = async (newOnly) => {
    const contextId = selectedContextId.value;
    if (!contextId) {
      messages.value = [];
      return;
    }
    const params = { contextId };
    if (newOnly) {
      params.skipToId = messages.value.slice(-1)[0]?.id;
    }
    let newItems = [];
    try {
      newItems = await api.get('chat/message/list', { params });
    } catch {
      //
    }
    if (params.skipToId) {
      newItems.forEach((m) => insertMessage(m));
    } else {
      messages.value = newItems;
    }
  };

  const loadMessage = async (id) => {
    const message = await api.get('chat/message/get', { params: { id } });
    insertMessage(message);
    return message;
  };

  const saveMessage = async (id, fields) => {
    await api.post('chat/message/save', { ...fields, id });
    await loadMessage(id);
  };

  const removeMessage = async (id) => {
    removeItem(messages.value, (m) => m.id === id);
    return api.post('chat/message/remove', { id }).catch(() => null);
  };

  const voiceMessage = async (id) => {
    await api.post('chat/message/voice', { id });
    await loadMessage(id);
  };

  const sendMessage = async (contextId, message, fields) => {
    await loadMessage(await api.post('chat/message/send', { contextId, message, ...fields }));
  };

  const generateMessage = async ({ contextId, messageId }) => {
    await api.post('chat/generator/generate', { contextId, messageId });
    loadContext(context?.value.id).catch(() => null);
  };

  const insertContext = (ctx) => replaceOrPushItem(contexts.value, ctx, (a, b) => a.id === b.id);

  const loadContexts = async () => {
    contexts.value = await api.get('chat/context/list');
  };

  const loadContext = async (id) => {
    insertContext(await api.get('chat/context/get', { params: { id } }));
  };

  const saveContext = async (id, fields) => {
    const newId = await api.post('chat/context/save', { ...fields, id });
    await loadContext(newId);
    return newId;
  };

  const removeContext = async (id) => {
    removeItem(contexts.value, (c) => c.id === id);
    return api.post('chat/context/remove', { id }).catch(() => null);
  };

  const selectContext = async (id) => {
    if (id) {
      await loadContext(id);
    }
    messages.value = [];
    selectedContextId.value = id;
  };

  const loadModels = async () => {
    models.value = await api.get('chat/model/list');
  };

  return {
    messages,
    contexts,
    models,
    context,
    loadMessages,
    saveMessage,
    removeMessage,
    voiceMessage,
    sendMessage,
    loadMessage,
    generateMessage,
    loadContexts,
    loadContext,
    saveContext,
    removeContext,
    selectContext,
    loadModels,
  };
});
