import { createRouter, createWebHistory } from 'vue-router';
import useAuthStore from '@/store/auth';
import DefaultLayout from '@/layouts/Default.vue';
import WebAppLayout from '@/layouts/WebApp.vue';
import StartView from '@/views/Start.vue';
import ChatView from '@/views/Chat.vue';
import ImagingView from '@/views/Imaging.vue';

const routes = [
  {
    path: '/playground',
    name: 'playground',
    component: () => import('@/views/Playground.vue'),
    meta: {
      layout: DefaultLayout,
    },
  }, {
    path: '/',
    name: 'home',
    meta: {
      requireAuth: true,
      layout: DefaultLayout,
    },
    children: [{
      path: '',
      component: StartView,
    }, {
      path: '/chat/:contextId(\\d+)?',
      name: 'chat',
      props: (route) => ({
        ...route.params,
        ...{ contextId: parseInt(route.params.contextId, 10) || 0 },
      }),
      component: ChatView,
    }, {
      path: '/imaging/:contextId(\\d+)?',
      name: 'imaging',
      props: true,
      component: ImagingView,
    }, {
      path: '/payment',
      name: 'payment',
      component: () => import('@/views/Payment.vue'),
    }, {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/Settings.vue'),
      children: [{
        path: '',
        redirect: { name: 'settings-account' },
      }, {
        path: '/settings/account',
        name: 'settings-account',
        component: () => import('@/views/settings/Account.vue'),
      }, {
        path: '/settings/chat',
        name: 'settings-chat',
        component: () => import('@/views/settings/Chat.vue'),
      }],
    }, {
      path: '/partner',
      name: 'partner',
      component: () => import('@/views/Partner.vue'),
      children: [{
        path: '',
        redirect: { name: 'partner-link' },
      }, {
        path: '/partner/link',
        name: 'partner-link',
        component: () => import('@/views/partner/Link.vue'),
      }, {
        path: '/partner/promocode',
        name: 'partner-promocode',
        component: () => import('@/views/partner/Promocode.vue'),
      }, {
        path: '/partner/referals',
        name: 'partner-referals',
        component: () => import('@/views/partner/Referals.vue'),
      }, {
        path: '/partner/bonus/:month(\\d{6})?',
        props: true,
        name: 'partner-bonus',
        component: () => import('@/views/partner/Bonus.vue'),
      }, {
        path: '/partner/balance',
        name: 'partner-balance',
        component: () => import('@/views/partner/Balance.vue'),
      }, {
        path: '/partner/payout',
        name: 'partner-payout',
        component: () => import('@/views/partner/Payout.vue'),
      }],
    }, {
      path: '/openapi',
      name: 'openapi',
      component: () => import('@/views/Openapi.vue'),
      children: [{
        path: '',
        redirect: { name: 'openapi-features' },
      }, {
        path: '/openapi/features',
        name: 'openapi-features',
        component: () => import('@/views/openapi/Features.vue'),
      }, {
        path: '/openapi/balance',
        name: 'openapi-balance',
        component: () => import('@/views/openapi/Balance.vue'),
      }, {
        path: '/openapi/key',
        name: 'openapi-key',
        component: () => import('@/views/openapi/Key.vue'),
      }],
    }, {
      path: '/cp',
      name: 'cp',
      component: () => import('@/views/ControlPanel.vue'),
      meta: {
        requireAdmin: true,
      },
      children: [{
        path: '',
        redirect: { name: 'cp-balance' },
      }, {
        path: '/cp/balance',
        name: 'cp-balance',
        component: () => import('@/views/control-panel/Balance.vue'),
      }, {
        path: '/cp/payout',
        name: 'cp-payout',
        component: () => import('@/views/control-panel/Payout.vue'),
      }, {
        path: '/cp/bonus/:month(\\d{6})?',
        name: 'cp-bonus',
        props: true,
        component: () => import('@/views/control-panel/Bonus.vue'),
      }, {
        path: '/cp/accounts',
        name: 'cp-accounts',
        component: () => import('@/views/control-panel/Accounts.vue'),
      }],
    }],
  }, {
    path: '/tgapp',
    name: 'tgapp',
    component: () => import('@/views/TelegramApp.vue'),
    meta: {
      layout: WebAppLayout,
    },
    children: [{
      path: '/tgapp/chat/message/:messageId(\\d+)',
      name: 'tgapp-chat-message',
      component: () => import('@/views/telegram-app/ChatMessage.vue'),
      props: true,
    }, {
      path: '/tgapp/imaging/inpaint/:imageId(\\d+)',
      name: 'tgapp-imaging-inpaint',
      component: () => import('@/views/telegram-app/ImagingInpaint.vue'),
      props: true,
    }],
  }, {
    path: '/kb',
    name: 'kb',
    component: () => import('@/views/KnowledgeBase.vue'),
    meta: {
      layout: DefaultLayout,
    },
    children: [{
      path: '/kb',
      name: 'kb-index',
      component: () => import('@/views/knowledge-base/Index.vue'),
      meta: {
        customSidebar: true,
      },
    }, {
      path: '/kb/search',
      name: 'kb-search',
      component: () => import('@/views/knowledge-base/Search.vue'),
      // meta: {
      //   customSidebar: true,
      // },
    }, {
      path: '/kb/:articleId(\\d+)',
      name: 'kb-view',
      component: () => import('@/views/knowledge-base/View.vue'),
      props: true,
    }, {
      path: '/kb/edit/:articleId(\\d+)',
      name: 'kb-edit',
      component: () => import('@/views/knowledge-base/Editor.vue'),
      props: true,
      meta: {
        requireAuth: true,
        requireAdmin: true,
      },
    }, {
      path: '/kb/create',
      name: 'kb-create',
      component: () => import('@/views/knowledge-base/Editor.vue'),
      meta: {
        requireAuth: true,
        requireAdmin: true,
      },
    }],
  }, {
    path: '/:code([a-z]\\d+)',
    name: 'partner-redirect',
    component: () => import('@/views/PartnerRedirect.vue'),
    props: true,
  }, {
    path: '/auth/:action(join|reset)?',
    name: 'auth',
    component: () => import('@/views/Auth.vue'),
    props: (route) => ({ ...route.params, ...route.query }),
  }, {
    path: '/auth/change-email',
    name: 'change-email',
    component: () => import('@/views/ChangeEmail.vue'),
    props: (route) => ({ ...route.params, ...route.query }),
  }, {
    path: '/auth/telegram',
    name: 'auth-telegram',
    component: () => import('@/views/TelegramAuth.vue'),
  }, {
    path: '/auth/vekrosta',
    name: 'auth-vekrosta',
    component: () => import('@/views/VekrostaAuth.vue'),
  }, {
    path: '/403',
    name: 'access-denied',
    component: () => import('@/views/AccessDenied.vue'),
    props: (route) => ({ ...route.query }),
  }, {
    path: '/404',
    name: 'not-found',
    component: () => import('@/views/NotFound.vue'),
    props: (route) => ({ ...route.query }),
  }, {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/NotFound.vue'),
    redirect: (to) => ({
      name: 'not-found',
      query: { url: to.fullPath },
    }),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(({ matched, fullPath }, from, next) => {
  const authStore = useAuthStore();
  const { isAdmin, isDeveloper } = authStore?.user ?? {};

  if (!authStore.token && matched.some((record) => record.meta.requireAuth)) {
    next({ name: 'auth', query: { url: fullPath } });
    return;
  }

  const accessDenied = matched.some(({ meta }) => {
    if (meta.requireAdmin && !isAdmin) {
      return true;
    }
    return meta.requireDeveloper && !isDeveloper;
  });

  if (accessDenied) {
    next({ name: 'access-denied', query: { url: fullPath } });
    return;
  }
  next();
});

export default router;
