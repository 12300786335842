import { computed } from 'vue';
import { defineStore } from 'pinia';
import { camelCase } from '@/utils/case';

const store = defineStore('app', () => {
  const utm = {};
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const storeUtm = () => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.forEach((value, key) => {
      if (/^utm_/i.test(key)) {
        utm[camelCase(key)] = value;
      }
    });
  };

  return {
    isMobile: computed(() => isMobile),
    utm: computed(() => utm),
    storeUtm,
  };
});

export default store;
